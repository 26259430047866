import React from "react"
import { connect } from "react-redux"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import styled from "styled-components"
import { Icon, Loader } from "@foris/foris-ui"
import { get } from "lodash"
import { VIEW_SETTINGS_VALUES } from "../../../../commonTypes"

import ReactTable from "react-table"
import "react-table/react-table.css"

const LoadingWrapper = styled.div`
  text-align: center;
  padding: 3em;
`

const TableWrapper = styled.div`
  font-size: 13px;
  & > h1 {
    font-size: 1.2em;
    font-weight: normal;
  }
  & > .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    height: 40px;
    line-height: 30px;
  }
  & > .ReactTable .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }
  & > .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 10px 5px;
  }
`

const LIST_TAB_QUERY = gql`
  query editorSearchResult($scenarioId: ID!, $originId: ID!, $filter: EditorSearchInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorSearch(filter: $filter) {
        items {
          id
          vacancies
          course {
            name
            curriculum {
              code
              name
            }
          }
          sessions {
            assignment {
              classroom {
                id
                code
              }
              instructor {
                code
                name
              }
            }
          }
          schedule {
            summaryByDay {
              day
              range {
                start
                end
              }
            }
            resourceAssigment {
              schedule
              classroom
              instructor
            }
          }
        }
        pageInfo {
          total
          hasPreviousPage
          hasNextPage
          page
          size
        }
      }
    }
  }
`

const columns = [
  {
    Header: "Asignatura",
    accessor: "course.name",
    Cell: (props: any) => <span className="number">{props.value}</span>,
    minWidth: 200
  },
  {
    Header: "Sec",
    accessor: "id",
    width: 70,
    Cell: (props: any) => <div style={{ textAlign: "center" }}>{props.value}</div>
  },
  {
    Header: (props: any) => <span>Vac</span>, // Custom header components!
    accessor: "vacancies",
    width: 40
  },
  {
    Header: "Lunes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "MONDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Martes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "TUESDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Miércoles",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "WEDNESDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Jueves",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "THURSDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Viernes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "FRIDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Sábado",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "SATURDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Domingo",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "SUNDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "H",
    accessor: "schedule.resourceAssigment.schedule",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  },
  {
    Header: "S",
    accessor: "schedule.resourceAssigment.classroom",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  },
  {
    Header: "D",
    accessor: "schedule.resourceAssigment.instructor",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  }
]

interface Filter {
  page: 1
  packageIds?: any
}

interface TableContainerVars {
  scenarioId: number
  originId: number
  filter?: { page?: number; sectionIds?: any }
}

const TableContainer: React.FC<{
  currentModuleConfig: any
  currentSectionInfo: any
  currentViewConfig: any
}> = ({ currentModuleConfig, currentSectionInfo, currentViewConfig }) => {
  const { loading, error, data } = useQuery<any, TableContainerVars>(LIST_TAB_QUERY, {
    variables: {
      scenarioId: currentModuleConfig.scenarioId,
      originId: currentModuleConfig.originId,
      filter: {
        page: 1,
        [VIEW_SETTINGS_VALUES[currentViewConfig.name].listTab.filter.paramPropertyKeyName]: get(
          currentSectionInfo,
          VIEW_SETTINGS_VALUES[currentViewConfig.name].listTab.filter.paramPropertyPathValue
        )
      }
    },
    fetchPolicy: "network-only"
  })

  if (loading)
    return (
      <LoadingWrapper>
        <Loader active />
      </LoadingWrapper>
    )
  if (error) return <p>{`Error! ${error}`}</p>

  const tableData = data && data.cube ? data.cube.editorSearch.items : [] || []

  return (
    <TableWrapper>
      <ReactTable
        data={tableData}
        columns={columns}
        className="-striped -highlight"
        minRows={1}
        showPagination={false}
        SubComponent={(row: any) => {
          console.log("row.original", row.original)
          return (
            <div style={{ padding: "0 0 0 2.5em" }}>
              <div style={{ display: "block", padding: "1.5em 0" }}>
                <div style={{ display: "inline-block", marginRight: "2em" }}>
                  <div style={{ display: "block", fontSize: "0.9em" }}>Currículo</div>
                  <div style={{ display: "block" }}>
                    {`${row.original.course.curriculum.code} ${
                      row.original.course.curriculum.name
                    }`}
                  </div>
                </div>
                <div style={{ display: "inline-block", marginRight: "2em" }}>
                  <div style={{ display: "block", fontSize: "0.9em" }}>Salas</div>
                  <div style={{ display: "block" }}>
                    {(() => {
                      const classrooms: any = []
                      row.original.sessions.map((item: any) => {
                        if (item.assignment.classroom)
                          classrooms.push(item.assignment.classroom.code)
                      })
                      const values = classrooms.length ? classrooms.join().replace(",", ", ") : "-"
                      return values
                    })()}
                  </div>
                </div>
                <div style={{ display: "inline-block", marginRight: "2em" }}>
                  <div style={{ display: "block", fontSize: "0.9em" }}>Docente</div>
                  <div style={{ display: "block" }}>
                    {(() => {
                      const instructors: any = []
                      row.original.sessions.map((item: any) => {
                        if (item.assignment.instructor)
                          instructors.push(
                            `${item.assignment.instructor.code}, ${item.assignment.instructor.name}`
                          )
                      })
                      const values = instructors.length ? instructors[0] : "-"
                      return values
                    })()}
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      />
    </TableWrapper>
  )
}

const ListTab: React.FC = ({ currentModuleConfig, currentSectionInfo, currentViewConfig }: any) => {
  return (
    <TableContainer
      currentViewConfig={currentViewConfig}
      currentSectionInfo={currentSectionInfo}
      currentModuleConfig={currentModuleConfig}
    />
  )
}

const mapStateToProps = (state: any) => ({
  currentViewConfig: state.canvasEditor.currentViewConfig,
  currentSectionInfo: state.canvasEditor.currentSectionInfo,
  currentModuleConfig: state.config.moduleConfig
})

export default connect(mapStateToProps)(ListTab)
