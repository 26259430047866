export enum EVENT_STATES {
  UNMODIFIED = "UNMODIFIED",
  MODIFIED = "MODIFIED",
  STAGED = "STAGED",
  UPDATED = "UPDATED"
}

export enum VIEW_TYPES {
  INSTRUCTOR = "INSTRUCTOR",
  CLASSROOM = "CLASSROOM",
  SECTION = "SECTION"
}

type EventError = {
  status: boolean
  details: any
}
export interface Event {
  id: string
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: any
  sessionId: number
  sectionId: number
  styles: { categoryID: number }
  error: EventError
  prevResource?: null | any
  state: EVENT_STATES
}

export interface Week {
  id: string
  value: string
  status: string
  title: string
  endingDate: Date
  startingDate: Date
  eventCount: string
}

export interface ViewConfig {
  name: string
  id: number
}

export interface EventList extends Array<Event> {}
export interface WeekList extends Array<Week> {}

export const SET_CURRENT_EVENT_LIST = "SET_CURRENT_EVENT_LIST"
interface SetCurrentEventListAction {
  type: typeof SET_CURRENT_EVENT_LIST
  payload: EventList
}

export const SET_ERROR_STATUS = "SET_ERROR_STATUS"

export interface EventErrorStatusChangeAction {
  commited: boolean
  sessionId: number
  validationErrors: any
}

interface SetErrorStatusAction {
  type: typeof SET_ERROR_STATUS
  payload: EventErrorStatusChangeAction
}

export const SET_CURRENT_SECTION_INFO = "SET_CURRENT_SECTION_INFO"
interface SetCurrentSectionInfoAction {
  type: typeof SET_CURRENT_SECTION_INFO
  payload: any
}

export const SET_NEW_EVENTS_POSITION = "SET_NEW_EVENTS_POSITION"
interface SetNewEventsPositions {
  type: typeof SET_NEW_EVENTS_POSITION
  payload: any
}

export const SET_CURRENT_WEEK_LIST = "SET_CURRENT_WEEK_LIST"
interface SetNewCurrentWeekList {
  type: typeof SET_CURRENT_WEEK_LIST
  payload: WeekList
}

export const SET_CURRENT_SELECTED_WEEK = "SET_CURRENT_SELECTED_WEEK"
interface SetNewCurrentWeek {
  type: typeof SET_CURRENT_SELECTED_WEEK
  payload: Week
}

export const SET_CURRENT_SELECTED_WEEK_BY_POSITION = "SET_CURRENT_SELECTED_WEEK_BY_POSITION"
interface SetNewCurrentWeekByDate {
  type: typeof SET_CURRENT_SELECTED_WEEK_BY_POSITION
  payload: string
}

export const SET_CURRENT_VIEW_CONFIG = "SET_CURRENT_VIEW_CONFIG"
interface SetCurrentViewConfig {
  type: typeof SET_CURRENT_VIEW_CONFIG
  payload: ViewConfig
}

export enum FILTER_TYPES {
  SECTION = "SECTION"
}

export const SET_CURRENT_EVENT_FILTERS = "SET_CURRENT_EVENT_FILTERS"
export interface EventFilters {
  type: FILTER_TYPES
  filterCriteria: string
  values: Array<{ id: string; label: string }>
}
interface SetCurrentEventFilters {
  type: typeof SET_CURRENT_EVENT_FILTERS
  payload: EventFilters
}

export const SET_CURRENT_CALENDAR_RESTRICTIONS = "SET_CURRENT_CALENDAR_RESTRICTIONS"
export interface CalendarRestriction {
  id: string
  dayId: string
  end: Date
  start: Date
  defaultChecked: boolean
}
interface SetCurrentCalendarRestrictions {
  type: typeof SET_CURRENT_CALENDAR_RESTRICTIONS
  payload: Array<CalendarRestriction>
}
export interface SchedulerState {
  currentWeekList: WeekList | null
  currentEventList: EventList | null
  currentSectionInfo: any | null
  currentSelectedWeek: Week | null
  currentViewConfig: ViewConfig | null
  currentEventFilters: EventFilters | null
  currentCalendarRestrictions: Array<CalendarRestriction> | null
}

export type SchedulerActionsTypes =
  | SetCurrentEventListAction
  | SetErrorStatusAction
  | SetCurrentSectionInfoAction
  | SetNewEventsPositions
  | SetNewCurrentWeekList
  | SetNewCurrentWeek
  | SetNewCurrentWeekByDate
  | SetCurrentViewConfig
  | SetCurrentEventFilters
  | SetCurrentCalendarRestrictions
