import React, { Component } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import { Button, Icon, Loader } from "@foris/foris-ui"
import { EVENT_STATES } from "../../../../redux/types"

import moment from "moment"
import "moment/locale/es"

import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"
import "./Scheduler.scss"

const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

const messages = {
  allDay: "Todo el día",
  previous: "<",
  next: ">",
  today: "Hoy",
  month: "Mes",
  week: "Semana",
  day: "Día",
  agenda: "Agenda",
  date: "Fecha",
  time: "Hora",
  event: "Evento",
  showMore: (total: number) => `+ Ver más (${total})`
}

let formats = {
  dayFormat: "dddd",
  weekdayFormat: "dddd"
}

declare interface SchedulerProps {
  //initialConfig: any
  dateToDisplay: Date
  eventHandlers: any
  events: any
  restrictions: any
  containerHeight: number | null
}
class Scheduler extends Component<SchedulerProps, { events: any }> {
  componentDidMount() {
    document.getElementsByClassName("rbc-time-content")[0].scrollTop = 280
  }

  CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      toolbar.onNavigate("PREV")
    }

    const goToNext = () => {
      //toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("NEXT")
    }

    // const goToCurrent = () => {
    //   const now = new Date()
    //   toolbar.date.setMonth(now.getMonth())
    //   toolbar.date.setYear(now.getFullYear())
    //   toolbar.onNavigate("current")
    // }

    const goToDayView = () => {
      toolbar.onView("day")
    }

    const goToWeekView = () => {
      toolbar.onView("week")
    }

    const goToMonthView = () => {
      toolbar.onView("month")
    }

    const goToAgendaView = () => {
      toolbar.onView("agenda")
    }

    return (
      <div style={{ marginBottom: "1em" }}>
        <span
          style={{
            color: "rgb(130, 130, 130)",
            textAlign: "left",
            textTransform: "capitalize",
            fontWeight: 300,
            fontSize: "1.3em",
            paddingTop: "9px",
            display: "inline-block"
          }}
        >
          {toolbar.label}
        </span>
        <Button.Group basic floated="right" style={{ height: "30px" }}>
          <Button compact style={{ padding: "0px 18px" }} onClick={goToMonthView}>
            Mes
          </Button>
          <Button compact style={{ padding: "0px 18px" }} onClick={goToWeekView}>
            Semana
          </Button>
          <Button compact style={{ padding: "0px 18px" }} onClick={goToDayView}>
            Día
          </Button>
          <Button compact style={{ padding: "0px 18px" }} onClick={goToAgendaView}>
            Agenda
          </Button>
        </Button.Group>

        <Button.Group basic floated="right" style={{ marginRight: "10px", height: "30px" }}>
          <Button
            compact
            style={{ padding: "0px 9px", fontWeight: 400, color: "#00000099" }}
            icon="left chevron"
            onClick={goToBack}
          />
          <Button
            compact
            style={{ padding: "0px 9px", fontWeight: 400, color: "#00000099" }}
            icon="right chevron"
            onClick={goToNext}
          />
        </Button.Group>
      </div>
    )
  }

  CustomEvent = (props: any) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            background: "white",
            display: "block",
            top: "50%",
            right: "50%"
          }}
        >
          {props.event.state === EVENT_STATES.MODIFIED && <Loader active />}
        </div>

        {props.title}

        {props.event.error.status && (
          <Icon
            style={{
              color: "rgb(255, 255, 255)",
              position: "absolute",
              top: "2px",
              right: "-8px",
              fontSize: "1.2em"
            }}
            name="info circle"
          />
        )}
      </div>
    )
  }

  CustomEventWrapper = (props: any) => {
    const childrenComponent = {
      ...props.children,
      props: {
        ...props.children.props,
        children: {
          ...props.children.props.children,
          props: {
            ...props.children.props.children.props,
            className: `${props.children.props.children.props.className} categoryID__${props.event.styles.categoryID}`
          }
        }
      }
    }

    return (
      <div className={props.event.error.status ? "event__wrapper--with--error" : "event__wrapper"}>
        {childrenComponent}
      </div>
    )
  }

  CustomTimeSlotWrapper = (props: any) => {
    const children = { ...props.children }
    const restrictions = this.props.restrictions

    if (restrictions && restrictions.length) {
      const currentDate = moment(props.value)
      const currentDayNumber = moment(props.value).day()
      const currentDay = moment(props.value).format("YYYY-MM-D")
      let baseSlotClass = "rbc-time-slot"
      let timeSlotClassName = ""

      const checkAvailabilityInGroup = function(blockGroup: any) {
        let isAvailableinAnyBlock = false
        blockGroup.map((block: any, index: number) => {
          const startDate = moment(`${currentDay} ${block.start}`)
          const endDate = moment(`${currentDay} ${block.end}`)
          if (currentDate.isBetween(startDate, endDate, "minutes", "[)")) {
            isAvailableinAnyBlock = true
          }
        })

        return isAvailableinAnyBlock
      }

      restrictions.map((restrictionByType: any) => {
        if (
          restrictionByType.scheduleEvent[currentDayNumber] &&
          restrictionByType.scheduleEvent[currentDayNumber].length
        ) {
          const isAvailable = checkAvailabilityInGroup(
            restrictionByType.scheduleEvent[currentDayNumber]
          )

          if (isAvailable) {
            timeSlotClassName = baseSlotClass
          } else {
            timeSlotClassName = timeSlotClassName.includes("restrictedArea")
              ? timeSlotClassName
              : baseSlotClass + ` restrictedArea active ${restrictionByType.type}`
          }
        } else {
          timeSlotClassName = baseSlotClass + ` restrictedArea active ${restrictionByType.type}`
        }
        children.props = { ...props.children.props, className: timeSlotClassName }
      })
    }
    return children
  }

  render() {
    const { containerHeight, eventHandlers, dateToDisplay, events } = this.props

    return (
      <>
        {containerHeight && (
          <div style={{ padding: "0  0 3em 0", height: `${containerHeight}px` }}>
            <DragAndDropCalendar
              // selectable
              resizable
              formats={formats}
              localizer={localizer}
              events={events}
              onEventDrop={eventHandlers.onEventDrop}
              onEventResize={eventHandlers.onEventResize}
              onSelectSlot={eventHandlers.onCreateEvent}
              onSelectEvent={eventHandlers.onSelectEvent}
              onNavigate={eventHandlers.onToolbarNavigate}
              date={dateToDisplay}
              defaultView={"week"}
              messages={messages}
              components={{
                toolbar: this.CustomToolbar,
                event: this.CustomEvent,
                eventWrapper: this.CustomEventWrapper,
                timeSlotWrapper: this.CustomTimeSlotWrapper
              }}
            />
          </div>
        )}
      </>
    )
  }
}

export default Scheduler
