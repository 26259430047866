import React, { Suspense } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { moduleRoutes } from "./routes"
import { DarwinedConfig, ModuleConfig, ApiTypes, ApiProtocol } from "./config"

const config: DarwinedConfig = new ModuleConfig(
  "module_scheduler",
  "Scheduler",
  "FullPageSidebar",
  {
    type: ApiTypes.GRAPHQL,
    protocol: ApiProtocol.HTTP,
    endpoint: ""
  }
)

const ModuleLayout: any = React.lazy(() =>
  import(`../../common/components/layout/${config.layoutName}`)
)

console.log(config.toString())

const SchedulerOutlet = () => (
  <Switch>
    {moduleRoutes.map(route => (
      <Route key={route.path} path={route.path} component={route.component} />
    ))}
  </Switch>
)

const Scheduler: React.FC = () => <SchedulerOutlet />

export default Scheduler
