import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { Form, Grid, Icon, Container, Button } from "@foris/foris-ui"
import gql from "graphql-tag"
import { useQuery, useMutation } from "react-apollo"
import SearchClassRoom from "./SearchClassRoom"
import SearchInstructor from "./SearchInstructor"
import { UPDATE_EVENT_MUTATION } from "../../../../../canvas/queries"

import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

const GET_BLOCKS_RANGES = gql`
  query getBlocksRanges($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      allBlocks {
        id
        startingTime
        endingTime
      }
    }
  }
`
const CURRENT_OPERATION = {
  EDIT_SESSION: "EDIT_SESSION",
  EDIT_SESSION_AND_SKIP_VALIDATIONS: "EDIT_SESSION_AND_SKIP_VALIDATIONS"
}

const ERRORS = {
  ClassroomClash: "Choque de sala",
  InstructorClash: "Choque en instructor",
  LinkClash: "Choque en liga",
  SectionClash: "Choque en sección",
  PackageClash: "Choque en paquete",
  InvalidInstructorTravel: "Traslado de docente",
  InvalidPackageTravel: "Traslado de estudiantes",
  InvalidBuildingForCourse: "Edificio no permitido",
  InvalidBlock: "Rango de horarios inválido"
}

const ErrorContainer = styled.div`
  background-color: rgb(255, 95, 87);
  color: white;
  max-width: 400px;
  padding: 1.5em;
  & > .editorSectionTitle {
    font-size: 1.5em;
  }
  & > p {
    margin-bottom: 30px;
  }
`

const InfoColumn = styled.div`
  display: inline-block;
  min-width: 3em;
  margin-right: 1em;
  &.ico__column {
    margin-right: 0;
  }
`

const InfoItem = styled.div`
  display: inline;
  & > label {
    display: block;
    color: #828282;
    font-weight: 500;
    font-size: 12px;
  }
  & > span {
    display: inline;
    color: #333;
    font-weight: 400;
  }
  .text__ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-top: 2px;
    text-overflow: ellipsis;
    &.main__title {
      color: #2974b6;
    }
  }
`

const daysOptions = [
  { key: "l", text: "Lunes", value: "MONDAY" },
  { key: "m", text: "Martes", value: "TUESDAY" },
  { key: "mm", text: "Miércoles", value: "WEDNESDAY" },
  { key: "j", text: "Jueves", value: "THURSDAY" },
  { key: "v", text: "Viernes", value: "FRIDAY" },
  { key: "s", text: "Sábado", value: "SATURDAY" },
  { key: "d", text: "Domingo", value: "SUNDAY" }
]

const ModalWrapper = styled.div`
  & a {
    cursor: pointer;
    &.back__btn {
      position: absolute;
      top: 1.5em;
      left: 3em;
    }
    &.close__btn {
      position: absolute;
      top: 1.5em;
      right: 3em;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      z-index: 999;
    }
  }
  label {
    font-weight: 500;
    color: #4f4f4f;
  }
`

const DAY_NUMBER: any = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
}

interface EditModalProps {
  onClose: Function
  onEdit: Function
  currentModuleConfig: any
  event: any
}

const EditModal: React.FC<EditModalProps> = props => {
  console.log("-------------- props ---------", props)
  //notify
  const notificationDOMRef: any = useRef(null)

  const addNotification = (type: string, title: string, message: string) => {
    notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    })
  }

  const [formState, setFormState] = useState({
    dayName: "",
    startTime: "",
    endTime: "",
    classRoom: { id: null, label: "" },
    instructor: { id: null, label: "" }
  })

  const [visibleClassRoomSearch, setVisibleClassRoomSearch] = useState(false)
  const [visibleInstructorSearch, setVisibleInstructorSearch] = useState(false)

  //const { loading, error, data } = useQuery(GET_BLOCKS_RANGES, {
  const { data } = useQuery(GET_BLOCKS_RANGES, {
    variables: { scenarioId: props.currentModuleConfig.scenarioId }
  })

  //if (loading) return 'Loading...';
  //if (error) return `Error! ${error.message}`;

  const hoursOptions: any = []

  if (data && data.data && data.data.allBlocks) {
    const times = data.data.allBlocks.map((item: any) => [item.startingTime, item.endingTime])
    const uniqueDates = times
      .flat()
      .filter((item: any, pos: any) => times.flat().indexOf(item) == pos)

    uniqueDates.map((hour: any) =>
      hoursOptions.push({ key: hour, text: hour.replace(":00", ""), value: hour })
    )
  }

  const initialStateClassRomm = props.event.resource.classroom
    ? {
        id: props.event.resource.classroom.id,
        label: `${props.event.resource.classroom.code} - ${props.event.resource.classroom.name} ${
          props.event.resource.classroom.classroomType.name
        } | ${props.event.resource.classroom.capacity}(${props.event.resource.classroom.capacity}+${
          props.event.resource.classroom.allowedOverflow
        })`
      }
    : { id: null, label: "" }
  const initialStateInstructor = props.event.resource.instructor
    ? {
        id: props.event.resource.instructor.id,
        label: `${props.event.resource.instructor.name} ${props.event.resource.instructor.code}`
      }
    : { id: null, label: "" }

  useEffect(() => {
    setFormState({
      dayName: props.event.resource.blockRange.start.day,
      startTime: props.event.resource.blockRange.start.startingTime,
      endTime: props.event.resource.blockRange.end.endingTime,
      classRoom: initialStateClassRomm,
      instructor: initialStateInstructor
    })
  }, [])

  const onSelectedNewClassRoon = (classRoom: any, e: any) => {
    setFormState({
      ...formState,
      classRoom: {
        id: classRoom.id,
        label: `${classRoom.code} - ${classRoom.name} ${classRoom.classroomType.name} | ${
          classRoom.capacity
        }(${classRoom.capacity}+${classRoom.allowedOverflow})`
      }
    })
  }

  const onSelectedNewInstructor = (instructor: any, e: any) => {
    setFormState({
      ...formState,
      instructor: {
        id: instructor.id,
        label: `${instructor.name} ${instructor.code}`
      }
    })
  }

  const onChangeSelect = (e: any, data: any) => {
    setFormState({ ...formState, [data.name]: data.value })
  }

  /**
   * Mutation * */
  const [errorsState, setErrorsState] = useState({
    display: false,
    errorList: []
  })

  const [currentOperation, setCurrentOperation] = useState(CURRENT_OPERATION.EDIT_SESSION)
  const [updateSession, { loading, error }] = useMutation(UPDATE_EVENT_MUTATION, {
    onCompleted: (response: any) => {
      if (response.cube.editSession.commited) {
        // new hours
        const startingHour = response.cube.editSession.session.assignment.blockRange.start.startingTime.split(
          ":"
        )
        const endingHour = response.cube.editSession.session.assignment.blockRange.end.endingTime.split(
          ":"
        )

        // set date by dayname
        const date = props.event.start
        const daytoset = DAY_NUMBER[response.cube.editSession.session.assignment.day]
        const currentDay = date.getDay()
        const distance = daytoset - currentDay
        date.setDate(date.getDate() + distance)

        // create values for start and end dates
        const startingDate = new Date(date)
        const endingDate = new Date(date)

        // set new hours according starting and ending times
        startingDate.setHours(
          parseInt(startingHour[0]),
          parseInt(startingHour[1]),
          parseInt(startingHour[2])
        )
        endingDate.setHours(
          parseInt(endingHour[0]),
          parseInt(endingHour[1]),
          parseInt(endingHour[2])
        )

        props.onEdit({
          start: new Date(startingDate),
          end: new Date(endingDate),
          event: { ...props.event },
          sessionId: response.cube.editSession.session.id
        })

        addNotification(
          "success",
          "Sesión actualizada",
          "La sesión se ha actualizado correctamente"
        )
      } else {
        const errors = getErrorList(response.cube.editSession.validationErrors)
        setErrorsState({ ...errorsState, display: true, errorList: errors })
      }
    }
  })

  const getErrorList = (validationErrors: any) => {
    const errors = validationErrors
    const errorList = errors.map((item: any) => item.__typename)
    const uniqueList = errorList.filter((item: any, pos: any) => errorList.indexOf(item) === pos)
    return uniqueList
  }

  if (props.event.error.status && !errorsState.errorList.length)
    setErrorsState({
      ...errorsState,
      display: true,
      errorList: getErrorList(props.event.error.details)
    })

  return (
    <ModalWrapper>
      <ReactNotification ref={notificationDOMRef} />
      <Container fluid style={{ paddingTop: "3em" }}>
        <a className="close__btn" onClick={e => props.onClose(e)}>
          cerrar x
        </a>

        {!visibleClassRoomSearch && !visibleInstructorSearch && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Container style={{ padding: "0 3em" }}>
                  <h4 style={{ fontWeight: 400 }}>{`${props.event.resource.info.section.code} - ${
                    props.event.resource.info.course.name
                  }`}</h4>
                  <Form style={{ maxWidth: "534px" }}>
                    <Form.Group>
                      <Icon
                        style={{
                          fontSize: "1.5em",
                          color: "#BDBDBD",
                          paddingTop: "1.1em",
                          lineHeight: "0px"
                        }}
                        name="calendar alternate outline"
                      />
                      <InfoColumn style={{ marginLeft: "7px" }}>
                        <InfoItem>
                          <label>Asignatura</label>
                          <span className="text__ellipsis main__title">{`${
                            props.event.resource.info.course.code
                          } ${props.event.resource.info.course.name}`}</span>
                        </InfoItem>
                      </InfoColumn>
                      <InfoColumn>
                        <InfoItem>
                          <label>Comp</label>
                          <span style={{ fontWeight: 500 }}>
                            {props.event.resource.info.courseComponent.component.code}
                          </span>
                        </InfoItem>
                      </InfoColumn>
                      <InfoColumn>
                        <InfoItem>
                          <label>Sección</label>
                          <span style={{ fontWeight: 500 }}>
                            {props.event.resource.info.section.id}
                          </span>
                        </InfoItem>
                      </InfoColumn>
                      <InfoColumn>
                        <InfoItem>
                          <label>Vac</label>
                          <span style={{ fontWeight: 500 }}>
                            {props.event.resource.info.section.vacancies}
                          </span>
                        </InfoItem>
                      </InfoColumn>
                    </Form.Group>

                    <Form.Group>
                      <Icon
                        style={{ fontSize: "1.5em", color: "#BDBDBD", paddingTop: "1.1em" }}
                        name="clock outline"
                      />
                      {/* <Form.Select width={7} label="Semanas" options={[]} placeholder="Semanas" /> */}
                      <Form.Select
                        width={4}
                        label="Día"
                        options={daysOptions}
                        value={formState.dayName}
                        placeholder="Día"
                        name={"dayName"}
                        onChange={onChangeSelect}
                      />
                      <Form.Select
                        width={3}
                        label="Horario"
                        placeholder="Horario"
                        options={hoursOptions}
                        value={formState.startTime}
                        name={"startTime"}
                        onChange={onChangeSelect}
                      />
                      <Form.Select
                        width={3}
                        label={" -"}
                        placeholder="Horario"
                        value={formState.endTime}
                        options={hoursOptions}
                        name={"endTime"}
                        onChange={onChangeSelect}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Icon
                        style={{ fontSize: "1.5em", color: "#BDBDBD", paddingTop: "1.1em" }}
                        name="building outline"
                      />
                      <Form.Input
                        action={{
                          icon: "search",
                          onClick: () => {
                            setVisibleInstructorSearch(false)
                            setVisibleClassRoomSearch(true)
                          }
                        }}
                        width={12}
                        label="Sala"
                        value={formState.classRoom.label}
                        placeholder="Sala"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Icon
                        style={{ fontSize: "1.5em", color: "#BDBDBD", paddingTop: "1.1em" }}
                        name="user outline"
                      />
                      <Form.Input
                        action={{
                          icon: "search",
                          onClick: () => {
                            setVisibleClassRoomSearch(false)
                            setVisibleInstructorSearch(true)
                          }
                        }}
                        width={12}
                        label="Docente"
                        value={formState.instructor.label}
                        placeholder="Docente"
                      />
                    </Form.Group>

                    <div style={{ padding: "1em 0 0 2em" }}>
                      <div style={{ display: "inline-block", marginRight: "10px" }}>
                        <Form.Button
                          primary
                          onClick={(e: any) => {
                            e.preventDefault()
                            const params = {
                              originId: props.currentModuleConfig.originId,
                              scenarioId: props.currentModuleConfig.scenarioId,
                              input: {
                                sessionId: parseInt(props.event.sessionId),
                                dryRun: false,
                                skipValidations: false,
                                clientMutationId: "mutation",
                                changeset: {
                                  changeBlocks: {
                                    op: "CHANGE",
                                    day: formState.dayName,
                                    startTime: formState.startTime,
                                    endTime: formState.endTime
                                  },
                                  changeClassroom: {
                                    op: "CHANGE",
                                    classroomId: formState.classRoom.id
                                  },
                                  changeInstructor: {
                                    op: "CHANGE",
                                    instructorId: formState.instructor.id
                                  }
                                }
                              }
                            }
                            setCurrentOperation(CURRENT_OPERATION.EDIT_SESSION)
                            updateSession({ variables: { ...params } })
                          }}
                          loading={loading && currentOperation === CURRENT_OPERATION.EDIT_SESSION}
                        >
                          Guardar
                        </Form.Button>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <Form.Button onClick={(e: any) => props.onClose(e)}>Cancelar</Form.Button>
                      </div>
                    </div>
                  </Form>
                </Container>
              </Grid.Column>
              <Grid.Column width={6}>
                {(errorsState.display || props.event.error.status) && (
                  <ErrorContainer>
                    <span className="editorSectionTitle">La operación es inválida</span>
                    <ul>
                      {errorsState.errorList.map((item, index) => (
                        <li key={index}> {ERRORS[item]}</li>
                      ))}
                    </ul>
                    <p>
                      {" "}
                      Modifique los datos de la sesión y realice una validación nuevamente o ignore
                      esta validación.{" "}
                    </p>
                    <Button
                      loading={
                        loading &&
                        currentOperation === CURRENT_OPERATION.EDIT_SESSION_AND_SKIP_VALIDATIONS
                      }
                      inverted
                      onClick={(e: any) => {
                        e.preventDefault()
                        const params = {
                          originId: props.currentModuleConfig.originId,
                          scenarioId: props.currentModuleConfig.scenarioId,
                          input: {
                            sessionId: parseInt(props.event.sessionId),
                            dryRun: false,
                            skipValidations: true,
                            clientMutationId: "mutation",
                            changeset: {
                              changeBlocks: {
                                op: "CHANGE",
                                day: formState.dayName,
                                startTime: formState.startTime,
                                endTime: formState.endTime
                              },
                              changeClassroom: {
                                op: "CHANGE",
                                classroomId: formState.classRoom.id
                              },
                              changeInstructor: {
                                op: "CHANGE",
                                instructorId: formState.instructor.id
                              }
                            }
                          }
                        }
                        setCurrentOperation(CURRENT_OPERATION.EDIT_SESSION_AND_SKIP_VALIDATIONS)
                        updateSession({ variables: { ...params } })
                      }}
                    >
                      Ignorar Validaciones
                    </Button>
                  </ErrorContainer>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {visibleClassRoomSearch && (
          <SearchClassRoom
            onSelectedNewClassRoon={(classRoom: any, e: any) => {
              onSelectedNewClassRoon(classRoom, e)
              setVisibleClassRoomSearch(false)
            }}
            setVisibleClassRoomSearch={() => {
              setVisibleClassRoomSearch(false)
            }}
          />
        )}

        {visibleInstructorSearch && (
          <SearchInstructor
            onSelectedNewInstructor={(instructor: any, e: any) => {
              onSelectedNewInstructor(instructor, e)
              setVisibleInstructorSearch(false)
            }}
            setVisibleInstructorSearch={() => {
              setVisibleInstructorSearch(false)
            }}
          />
        )}
      </Container>
    </ModalWrapper>
  )
}

const mapStateToProps = (state: any) => ({
  currentModuleConfig: state.config.moduleConfig
})

export default connect(mapStateToProps)(EditModal)
