import React, { Component } from "react"
import { Container } from "@foris/foris-ui"
import { SessionAccordion, SectionInfo } from "./components"
import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"

const data = {
  info: {
    sede: "MAD",
    escuela: "28051852",
    modalidad: "TR",
    jornada: "U",
    carrera: "9998",
    curriculo: "001",
    nivel: "600",
    grupo: "MG6",
    seccion: "303319",
    paquetes: ["U:TR:9998:001:600:9"],
    modulacion: "1:3",
    estandar: "40",
    franjas: "MAN, MAN1, MAN2, MAN3, MAN4, MAN5",
    tipoSala: "HOSP-SIM-COMP",
    vacantes: "16"
  },
  sessions: [
    {
      title: "Sesión 4636662019/10-14 - 2020/01-26 Miér 09:30-12:30 Sala: MAD-A - A111",
      day: "Miércoles",
      hours: {
        init: "09:30",
        end: "12:30"
      },
      classroom: "Aula: A111 | Edificio: MAD-A | 24+0=24",
      instructor: "Juan Perez"
    },
    {
      title: "Sesión 1116662019/10-14 - 2020/01-26 Miér 09:30-12:30 Sala: MAD-E - A222",
      day: "Miércoles",
      hours: {
        init: "19:30",
        end: "21:30"
      },
      classroom: "Aula: A222 | Edificio: MAD-H | 24+0=24",
      instructor: "María Gonzalez"
    }
  ]
}
class Editor extends Component {
  render() {
    return (
      <FullPageSidebar displayContextSearch={true}>
        <Container
          style={{
            backgroundColor: "#0b518e",
            padding: "3em",
            marginBottom: "3em"
          }}
          fluid
        >
          <SectionInfo {...{ info: data.info }} />
        </Container>
        <Container style={{ padding: "0 3em" }} fluid>
          <SessionAccordion {...{ sessions: data.sessions }} />
        </Container>
      </FullPageSidebar>
    )
  }
}

export default Editor
