import React, { Component } from "react"
import { Accordion, Icon } from "@foris/foris-ui"

const options = [
  { key: "l", text: "Lunes", value: "l" },
  { key: "m", text: "Martes", value: "m" },
  { key: "mm", text: "Miércoles", value: "mm" },
  { key: "j", text: "Jueves", value: "j" },
  { key: "v", text: "Viernes", value: "v" },
  { key: "s", text: "Sábado", value: "s" },
  { key: "d", text: "Domingo", value: "d" }
]

export default class SessionItem extends Component<
  { active: boolean; index: number; onClick: any },
  {}
> {
  state = { value: null }

  render() {
    const props = this.props
    return (
      <>
        <Accordion.Title active={props.active} index={props.index} onClick={props.onClick}>
          <Icon name="dropdown" />
          Sesión 463666 2019/10-14 - 2020/01-26 Miér 09:30-12:30 Sala: MAD-A - A111
        </Accordion.Title>
        <Accordion.Content active={props.active}></Accordion.Content>
      </>
    )
  }
}
