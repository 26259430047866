import React from "react"
import { Link } from "react-router-dom"
import { Grid } from "@foris/foris-ui"
import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"

import ContextSearchLocalInstance from "../../../../common/components/layout/FullPageSidebar/components/ContextSearch"

import logo from "../../../../assets/svg/Darwin_Editor_invert.svg"

const EditorHome: React.FC = () => {
  return (
    <FullPageSidebar displayContextSearch={false}>
      <Grid
        textAlign="center"
        style={{
          height: "calc(100vh  - 38px)",
          backgroundColor: "#2772b5",
          color: "white",
          overflowY: "hidden"
        }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: "700px" }}>
          <img width="400" height="auto" alt="Editor Darwined" src={logo} />
          <div style={{ display: "block", margin: "0 auto 3em auto", maxWidth: "466px" }}>
            <ContextSearchLocalInstance />
          </div>
          <hr style={{ borderTop: "none", borderBottom: "1px solid #baccdc", marginTop: "3em" }} />
          <p>
            <Link style={{ color: "white", paddingRight: "1em" }} to="#">
              Ver historial de cambios »
            </Link>
            <Link style={{ color: "white", paddingRight: "1em" }} to="#">
              Descargar reporte de horarios »
            </Link>
            <Link style={{ color: "white" }} to="#">
              Volver al módulo de horarios »
            </Link>
          </p>
        </Grid.Column>
      </Grid>
    </FullPageSidebar>
  )
}

export default EditorHome
