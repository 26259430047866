import React, { Component } from "react"
import { Link } from "react-router-dom"
import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"

class Landing extends Component {
  render() {
    return (
      <FullPageSidebar displayContextSearch={true}>
        <h1>Módulo de horarios</h1>
        <h2>(graficos)</h2>
        <ul>
          <li>
            <Link to={"/scheduler/editor"}>Editor</Link>
          </li>
          <li>otro</li>
          <li>otro</li>
          <li>otro</li>
          <li>otro</li>
          <li>otro</li>
        </ul>
      </FullPageSidebar>
    )
  }
}

export default Landing
