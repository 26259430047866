import {
  SearchActionTypes,
  SET_FILTER,
  Filter,
  SET_SEARCH_CRITERIA,
  RECEIVE_RESULT_LIST
} from "./types"

export function setFilter(newFilter: Filter): SearchActionTypes {
  return {
    type: SET_FILTER,
    payload: newFilter
  }
}

export function setSearchCriteria(searchCriteria: string): SearchActionTypes {
  return {
    type: SET_SEARCH_CRITERIA,
    payload: searchCriteria
  }
}

export function setCurrentSearchResultsList(resultList: any): SearchActionTypes {
  return {
    type: RECEIVE_RESULT_LIST,
    payload: resultList
  }
}
