import React from "react"
import { Popup, Grid, Icon } from "@foris/foris-ui"
import styled from "styled-components"

import datosIcon from "../../../../../assets/svg/datos_icon.svg"
import proyeccionesIcon from "../../../../../assets/svg/proyecciones_icon.svg"
import procesosIcon from "../../../../../assets/svg/procesos_icon.svg"
import horariosIcon from "../../../../../assets/svg/horarios_icon.svg"

const ModuleOption = styled.div`
  display: block;
  font-size: 0.9em;
  & :hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
  & > div {
    display: inline-block;
    padding: 0.5em;
    & > div {
      display: block;
      width: 97px;
    }
  }
`

const MainMenu = () => (
  <Popup
    style={{ marginRight: "45px" }}
    className="right"
    trigger={<Icon name="buromobelexperte" size="big" />}
    flowing
    on="click"
  >
    <Grid centered columns={1}>
      <Grid.Column textAlign="center">
        <ModuleOption>
          <div>
            <div>
              <img src={datosIcon} width="40" height="auto" />
            </div>
            <div>Catálogos</div>
          </div>
          <div>
            <div>
              <img src={proyeccionesIcon} width="40" height="auto" />
            </div>
            <div>Proyecciones</div>
          </div>
        </ModuleOption>
        <ModuleOption>
          <div>
            <div>
              <img src={procesosIcon} width="40" height="auto" />
            </div>
            <div>Asignaciones</div>
          </div>
          <div>
            <div>
              <img src={horariosIcon} width="40" height="auto" />
            </div>
            <div>Horarios</div>
          </div>
        </ModuleOption>
      </Grid.Column>
    </Grid>
  </Popup>
)

export default MainMenu
