/*eslint-disable react/react-in-jsx-scope*/

import Landing from "./pages/landing"
import EditorHome from "./pages/home"
import Editor from "./pages/editor"
// import SectionCanvas from "./pages/canvas/SectionCanvas"
import Canvas from "./pages/canvas/Canvas"
import Schedule from "./pages/schedule"
import Results from "./pages/results"

export const base = "/scheduler"

export const moduleRoutes = [
  {
    path: `${base}/editor/search`,
    component: Results,
    name: "Results"
  },
  {
    path: `${base}/editor/horario`,
    component: Schedule,
    name: "Schedule"
  },
  {
    path: `${base}/editor/instructor/:id`,
    component: Canvas,
    name: "InstructorCanvas"
  },
  {
    path: `${base}/editor/section/:id`,
    component: Canvas,
    name: "SectionCanvas"
  },
  {
    /**
     * FAKE MODULE */
    path: `${base}/editor/session`,
    component: Editor,
    name: "Editor"
  },
  {
    path: `${base}/editor`,
    component: EditorHome,
    name: "EditorHome"
  },
  {
    path: base,
    component: Landing,
    name: "Landing"
  }
]
