import React, { Component } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import moment from "moment"
import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"

import "react-big-calendar/lib/css/react-big-calendar.css"

// import * as dates from "react-big-calendar/lib/utils/dates"

const DragAndDropCalendar = withDragAndDrop(Calendar)

const events = [
  {
    id: 0,
    title: "Curso Gatsby JS",
    start: new Date(2018, 0, 29, 9, 0, 0),
    end: new Date(2018, 0, 29, 13, 0, 0),
    resourceId: 1,
    extra: {
      darwinedID: "001_+11",
      stringValue: "hello World",
      arrayOfNumbers: [11, 22, 33, 44]
    }
  },
  {
    id: 1,
    title: "Introducción a Darwined 1.0",
    allDay: true,
    start: new Date(2018, 0, 29, 14, 0, 0),
    end: new Date(2018, 0, 29, 16, 30, 0),
    resourceId: 2,
    extra: {
      darwinedID: "001_+22",
      stringValue: "hello World",
      arrayOfNumbers: [11, 22, 33, 44]
    }
  },
  {
    id: 2,
    title: "Team lead meeting",
    start: new Date(2018, 0, 29, 8, 30, 0),
    end: new Date(2018, 0, 29, 12, 30, 0),
    resourceId: 3,
    extra: {
      darwinedID: "001_+33",
      stringValue: "hello World",
      arrayOfNumbers: [11, 22, 33, 44]
    }
  },
  {
    id: 11,
    title: "Birthday Party",
    start: new Date(2018, 0, 30, 7, 0, 0),
    end: new Date(2018, 0, 30, 10, 30, 0),
    resourceId: 4,
    extra: {
      darwinedID: "001_+44",
      stringValue: "hello World",
      arrayOfNumbers: [11, 22, 33, 44]
    }
  }
]

const resourceMap = [
  { resourceId: 1, resourceTitle: "Board room" },
  { resourceId: 2, resourceTitle: "Training room" },
  { resourceId: 3, resourceTitle: "Meeting room 1" },
  { resourceId: 4, resourceTitle: "Meeting room 2" }
]

const localizer = momentLocalizer(moment)

// MONTH: 'month';
// WEEK: 'week';
// WORK_WEEK: 'work_week';
// DAY: 'day';
// AGENDA: 'agenda';

// const ColoredDateCellWrapper = ({ children }: any) =>
//   React.cloneElement(React.Children.only(children), {
//     style: {
//       backgroundColor: "lightblue"
//     }
//   })

// class Schedule extends Component {
//   render() {
//     return (
//       <div style={{ padding: "3em 6em" }}>
//         <h1>Horario Docente</h1>
//         <Calendar
//           events={events}
//           localizer={localizer}
//           defaultView="day"
//           views={["day", "work_week", "agenda", "month"]}
//           step={60}
//           defaultDate={new Date(2018, 0, 29)}
//           resources={resourceMap}
//           resourceIdAccessor="resourceId"
//           resourceTitleAccessor="resourceTitle"
//         />
//       </div>
//     )
//   }
// }

class Schedule extends Component<{}, { events: any }> {
  state = {
    events: events
  }
  constructor(props: {}) {
    super(props)

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }: any) {
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    console.log("Resource ID: ", events[idx].id)
    console.log("From: ", events[idx].start)
    console.log("To: ", updatedEvent.start)
    console.log("Object = ", updatedEvent)

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    let isValid = true

    if (events[idx].extra.darwinedID === "001_+11") isValid = false

    if (isValid) {
      this.setState({
        events: nextEvents
      })

      // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    } else {
      window.alert("Posición inválida. No puedes mover a esta posición")
    }
  }

  resizeEvent = ({ event, start, end }: any) => {
    const { events } = this.state

    const nextEvents = events.map((existingEvent: any) => {
      return existingEvent.id == event.id ? { ...existingEvent, start, end } : existingEvent
    })

    this.setState({
      events: nextEvents
    })

    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  newEvent(event: any) {
    // let idList = this.state.events.map(a => a.id)
    // let newId = Math.max(...idList) + 1
    // let hour = {
    //   id: newId,
    //   title: 'New Event',
    //   allDay: event.slots.length == 1,
    //   start: event.start,
    //   end: event.end,
    // }
    // this.setState({
    //   events: this.state.events.concat([hour]),
    // })
  }

  render() {
    return (
      <FullPageSidebar displayContextSearch={true}>
        <div style={{ padding: "3em 6em" }}>
          <h1>Horario Docente</h1>
          <DragAndDropCalendar
            selectable
            localizer={localizer}
            events={this.state.events}
            onEventDrop={this.moveEvent}
            resizable
            onEventResize={this.resizeEvent}
            onSelectSlot={this.newEvent}
            // onDragStart={console.log}
            defaultView={"week"}
            defaultDate={new Date(2018, 0, 29)}
          />
        </div>
      </FullPageSidebar>
    )
  }
}

export default Schedule
