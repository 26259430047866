export interface Filter {
  label: string | null
  type: string | null
  resultTemplate: string | null
}

export interface ContextSearchState {
  currentFilter: null | Filter
  currentSearchCriteria: null | string
  currentResultList: null | any
}

export const SET_FILTER = "SET_FILTER"
interface SetFilterAction {
  type: typeof SET_FILTER
  payload: Filter
}

export const SET_SEARCH_CRITERIA = "SET_SEARCH_CRITERIA"
interface SetSearchCriteriaAction {
  type: typeof SET_SEARCH_CRITERIA
  payload: string
}

export const RECEIVE_RESULT_LIST = "RECEIVE_RESULT_LIST"

interface SetCurrentSearchResultsList {
  type: typeof RECEIVE_RESULT_LIST
  payload: any
}

export type SearchActionTypes =
  | SetFilterAction
  | SetSearchCriteriaAction
  | SetCurrentSearchResultsList
