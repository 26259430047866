enum SEARCH_QUERY_PARAMS_BY_TYPE {
  COURSE = "courseIds",
  SECTION = "sectionIds",
  POPULATION = "populationIds",
  PACKAGE = "packageIds",
  INSTRUCTOR = "instructorIds",
  CLASSROOM = "classroomIds"
}

const VIEW_SETTINGS_VALUES: any = {
  SECTION: {
    headerData: {
      avatarLabel: {
        accessor: "avatarStringLabel"
      },
      beforeTitleLabel: {
        accessor: "beforeTitleLabel"
      },
      titleLabel: {
        accessor: "titleLabel"
      },
      columns: [
        {
          label: "Sede",
          accessor: "campus.code"
        },
        {
          label: "Escuela",
          accessor: "department.code"
        },
        {
          label: "Modalidad",
          accessor: "modality.code"
        },
        {
          label: "Jornada",
          accessor: "shift.name"
        },
        {
          label: "Carrera",
          accessor: "program.code"
        },
        {
          label: "Currículo",
          accessor: "curriculum.code"
        },
        {
          label: "Nivel",
          accessor: "course.level"
        },
        {
          label: "Modulación",
          accessor: "courseComponent.modulation"
        },
        {
          label: "Estándar",
          accessor: "courseComponent.academicStandard"
        },
        {
          label: "Vacantes",
          accessor: "section.vacancies"
        },
        {
          label: "Franjas",
          accessor: "stripeLabel"
        },
        {
          label: "Tipos de sala",
          accessor: "classRoomTypeLabel",
          width: "150px"
        }
      ]
    },
    listTab: {
      filter: {
        paramPropertyKeyName: SEARCH_QUERY_PARAMS_BY_TYPE.SECTION,
        paramPropertyPathValue: "section.code"
      }
    }
  },
  INSTRUCTOR: {
    headerData: {
      avatarLabel: {
        accessor: "avatarStringLabel"
      },
      beforeTitleLabel: {
        accessor: "beforeTitleLabel"
      },
      titleLabel: {
        accessor: "titleLabel"
      },
      columns: [
        {
          label: "Contrato",
          accessor: "contractLabel"
        },
        {
          label: "Prioridad",
          accessor: "priority"
        },
        {
          label: "Bloques",
          accessor: "blocksLabel"
        },
        {
          label: "Secciones",
          accessor: "sectionsLabel"
        },
        {
          label: "Asignaturas",
          accessor: "coursesLabel"
        }
      ]
    },
    listTab: {
      filter: {
        paramPropertyKeyName: SEARCH_QUERY_PARAMS_BY_TYPE.INSTRUCTOR,
        paramPropertyPathValue: "instructor.id"
      }
    }
  }
}

export { VIEW_SETTINGS_VALUES }
