import React, { Component, Suspense } from "react"
import { connect } from "react-redux"
import { Grid, Loader, TransitionablePortal, WeeksViewer } from "@foris/foris-ui"
import {
  InfoPopup,
  ErrorPopup,
  EditModal,
  CategoriesFilter,
  RestrictedAreasFilter
} from "./components"

import {
  updateErrorStatus,
  onUpdateLocalPositions,
  setEventList,
  setCurrentWeek,
  setCurrentWeekByPosition
} from "../../redux/actions"
import close__btn from "../../../../../../assets/img/close__icon.png"
import SchedulerAPI from "./components/Scheduler/SchedulerAPI"
import styled from "styled-components"
import {
  Event,
  Week,
  WeekList,
  ViewConfig,
  EventFilters,
  VIEW_TYPES,
  CalendarRestriction
} from "../../../canvas/redux/types"

/*** Load SchedulerComponent */
const SchedulerLazyComponent = React.lazy(() => import("./components/Scheduler/Scheduler"))

const CUSTOM_FULLSPAGE_DIFF = 350
const INFO_PUPUP_WIDTH = 479
const INFO_POPUP_MIN_HEIGHT = 325
const ERROR_PUPUP_WIDTH = 436
const ERROR_POPUP_MIN_HEIGHT = 325

interface IEditionModalProps {
  posX: string
  posY: string
}

const InfoPupupWrapper = styled.div<Pick<IEditionModalProps, "posX" | "posY">>`
  left: ${p => p.posX};
  top: ${p => p.posY};
  background-color: transparent;
  width: ${INFO_PUPUP_WIDTH}px;
  height: ${INFO_POPUP_MIN_HEIGHT}px;
  position: absolute;
  overflow: hidden;
  z-index: 9999;
`

const ErrorPopupWrapper = styled.div<Pick<IEditionModalProps, "posX" | "posY">>`
  left: ${p => p.posX};
  top: ${p => p.posY};
  background-color: transparent;
  width: ${ERROR_PUPUP_WIDTH}px;
  height: ${ERROR_POPUP_MIN_HEIGHT}px;
  position: absolute;
  overflow: hidden;
  z-index: 9999;
`

const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateEventListLocal: (newEventList: Array<Event>) => {
      dispatch(setEventList(newEventList))
    },
    onUpdateLocalPositions: (newEventList: Array<Event>, currentEvent: Event) => {
      dispatch(onUpdateLocalPositions(newEventList, currentEvent))
    },
    onUpdateErrorStatus: (newStatus: any) => {
      dispatch(updateErrorStatus(newStatus))
    },
    onUndoPosition: (newEventList: Array<Event>) => {
      dispatch(setEventList(newEventList))
    },
    onSelectWeek: (selectedWeek: Week) => {
      dispatch(setCurrentWeek(selectedWeek))
    },
    onSelectWeekByPosition: (position: string) => {
      dispatch(setCurrentWeekByPosition(position))
    }
  }
}

interface ISchedulerState {
  height: number | null
  loading: boolean
  editEventWindow: object
  displayInfoPopup: boolean
  displayErrorPopup: boolean
  currentSelectedEvent: any
  displayEditionModal: boolean
  //dateToDisplay: Date | null
  events: any
}
class SchedulerContainer extends Component<
  {
    currentWeekList: WeekList
    currentSelectedWeek: Week
    currentEventList: any
    updateEvent: any
    onUpdateErrorStatus: Function
    onUpdateLocalPositions: Function
    onUpdateEventListLocal: Function
    onSelectWeekByPosition: Function
    onUndoPosition: Function
    onSelectWeek: Function
    currentModuleConfig: any
    currentSectionInfo: any
    currentViewConfig: ViewConfig
    currentEventFilters: Array<EventFilters>
    currentCalendarRestrictions: Array<CalendarRestriction>
  },
  ISchedulerState
> {
  SAPI = new SchedulerAPI()

  state = {
    height: null,
    loading: true,
    editEventWindow: {
      posX: 0,
      posY: 0
    },
    displayInfoPopup: false,
    displayErrorPopup: false,
    currentSelectedEvent: {},
    displayEditionModal: false,
    events: this.props.currentEventList
  }

  // initialConfig = {
  //   getRestrictions: (date: Date) => {
  //     return this.SAPI.getRestrictions(this.props.currentCalendarRestrictions, date)
  //   }
  // }

  eventHandlers = {
    /**
     * Event Drag & Drop */
    onEventDrop: ({ event, start, end }: { event: Event; start: Date; end: Date }) => {
      const currentEvent = { ...event, start, end }
      const newEventList = this.SAPI.moveEvent(this.props.currentEventList, currentEvent)
      this.props.onUpdateLocalPositions(newEventList, currentEvent)
      // UPDATE CALENDAR COMPONENT --->
      this.setState({ events: this.props.currentEventList }, () => {
        this.updateEvent(currentEvent)
      })
    },

    /**
     * Change event Start and End dates ~*/
    onEventResize: ({ event, start, end }: { event: Event; start: Date; end: Date }) => {
      const currentEvent = { ...event, start, end }
      const newEventList = this.SAPI.moveEvent(this.props.currentEventList, currentEvent)
      this.props.onUpdateLocalPositions(newEventList, currentEvent)
      // UPDATE CALENDAR COMPONENT --->
      this.setState({ events: this.props.currentEventList }, () => {
        this.updateEvent(currentEvent)
      })
    },

    /**
     * handle mouse event ()click on checkbox */
    filterCategories: (
      e: React.FormEvent<HTMLSelectElement>,
      { id, checked, filtercriteria }: { id: number; checked: boolean; filtercriteria: string }
    ) => {
      const newEventList = this.SAPI.filterEventList(
        this.props.currentEventList,
        this.state.events,
        id,
        checked,
        filtercriteria
      )
      this.setState({ events: newEventList })
    },

    onFilterRestrictedAreas: (
      e: React.FormEvent<HTMLSelectElement>,
      { areatype, checked }: { areatype: string; checked: boolean }
    ) => {
      this.SAPI.filterRestrictedAreas({ areatype, checked })
    },

    onSelectEvent: (event: Object, e: any) => {
      const nativeEvent: any = e.nativeEvent
      const closestNode = nativeEvent.target.closest(".rbc-event")
      const viewportOffset = closestNode.getBoundingClientRect()

      // these are relative to the viewport, i.e. the window
      const viewportOffsetTop = viewportOffset.top
      const viewportOffsetLeft = viewportOffset.left

      // const { className: blockclasses = '' } = attrs
      const nodeStyles = window.getComputedStyle(closestNode)
      const halfOfWindowWidth = window.innerWidth / 2
      const halfOfWindowHeight = window.innerHeight / 2

      let finalXPosition = 0
      let finalYPosition = viewportOffsetTop - 70

      /**
       * Calc position relative to max right/left values
       */
      if (viewportOffsetLeft <= halfOfWindowWidth) {
        const refValue: number = nodeStyles.width ? parseInt(nodeStyles.width) : 0
        /* ------------------- Check if this is a proper position -------> */
        if (INFO_PUPUP_WIDTH + 100 > halfOfWindowWidth) {
          finalXPosition = viewportOffsetLeft - refValue
          /* <------------------- Check if this is a proper position ------- */
        } else {
          finalXPosition =
            viewportOffsetLeft +
            (nodeStyles.width && parseInt(nodeStyles.width.toString().replace("px", "")) + 10)
        }
      } else {
        finalXPosition = viewportOffsetLeft - 10 - INFO_PUPUP_WIDTH
      }

      /**
       * Calc position relative to max right/left values
       */
      if (viewportOffsetTop <= halfOfWindowHeight) {
        console.log("viewportOffsetTop <= halfOfWindowHeight")
        finalYPosition = viewportOffsetTop - 100
      } else {
        finalYPosition = viewportOffsetTop - (INFO_POPUP_MIN_HEIGHT - 100)
      }

      console.log("finalXPosition: ", finalXPosition)
      console.log("finalYPosition: ", finalYPosition)

      this.setState({
        editEventWindow: { posX: finalXPosition, posY: finalYPosition },
        currentSelectedEvent: event
      })

      if (e.target.className === "info circle icon") {
        this.setState({
          displayInfoPopup: false,
          displayErrorPopup: true
        })
      } else {
        this.setState({
          displayInfoPopup: true,
          displayErrorPopup: false
        })
      }
    },

    onToolbarNavigate: (date: Date, view: string, action: string) => {
      this.props.onSelectWeekByPosition(action)
    },

    closeInfoPopup: (e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) => {
      this.setState({ displayInfoPopup: false })
    },

    closeErrorPopup: (e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) => {
      this.setState({ displayErrorPopup: false })
    },

    openEditionModal: (e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) => {
      this.setState({
        displayEditionModal: true,
        displayInfoPopup: false
      })
    },

    closeEditionModal: (e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) => {
      this.setState({ displayEditionModal: false })
    }
  }

  updateEvent(eventObject: Event) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const startTime = eventObject.start
    const endTime = eventObject.end
    const sessionId = eventObject.sessionId

    const params = {
      originId: this.props.currentModuleConfig.originId,
      scenarioId: this.props.currentModuleConfig.scenarioId,
      input: {
        sessionId: sessionId,
        dryRun: false,
        skipValidations: false,
        clientMutationId: "mutation",
        changeset: {
          changeBlocks: {
            op: "CHANGE",
            day: days[startTime.getDay()].toUpperCase(),
            startTime: `${
              startTime.getHours().toString().length > 1
                ? startTime.getHours().toString()
                : "0" + startTime.getHours().toString()
            }:${
              startTime.getMinutes().toString().length > 1
                ? startTime.getMinutes().toString()
                : "0" + startTime.getMinutes().toString()
            }:00`,
            endTime: `${
              endTime.getHours().toString().length > 1
                ? endTime.getHours().toString()
                : "0" + endTime.getHours().toString()
            }:${
              endTime.getMinutes().toString().length > 1
                ? endTime.getMinutes().toString()
                : "0" + endTime.getMinutes().toString()
            }:00`
          }
        }
      }
    }

    this.props.updateEvent(params, (response: any) => {
      if (response.status === "ERROR") console.log("error", response)

      if (response.status === "OK") {
        //window.alert(`COMMITED: ${response.content.data.cube.editSession.commited}`)

        console.log("response.content > ", response.content)

        const eventStatus = {
          event: { ...response.content.data.cube.editSession.session },
          sessionId: sessionId,
          commited: response.content.data.cube.editSession.commited,
          validationErrors: response.content.data.cube.editSession.validationErrors
        }

        this.props.onUpdateErrorStatus(eventStatus)
        this.setState({ events: this.props.currentEventList })
      }
    })
  }

  updateEventTimes(event: Event) {
    const currentEvent = { ...event }
    console.log("currentEvent ---->", currentEvent)
    const newEventList = this.SAPI.moveEvent(this.props.currentEventList, currentEvent)
    this.props.onUpdateEventListLocal(newEventList)
    // UPDATE CALENDAR COMPONENT --->
    this.setState({ events: this.props.currentEventList }, () => {
      const eventStatus = {
        event: currentEvent,
        sessionId: currentEvent.sessionId,
        commited: true,
        validationErrors: []
      }
      this.props.onUpdateErrorStatus(eventStatus)
      this.setState({
        events: this.props.currentEventList,
        displayInfoPopup: false,
        displayErrorPopup: false
      })
    })
  }

  undoPosition(event: Event) {
    const currentEvent = { ...event }
    const newEventList = this.SAPI.moveEvent(this.props.currentEventList, currentEvent)
    this.props.onUndoPosition(newEventList)
    // UPDATE CALENDAR COMPONENT --->

    this.setState({ events: this.props.currentEventList }, () => {
      const eventStatus = {
        event: currentEvent,
        sessionId: currentEvent.sessionId,
        commited: true,
        validationErrors: []
      }
      this.props.onUpdateErrorStatus(eventStatus)
      this.setState({
        events: this.props.currentEventList,
        displayInfoPopup: false,
        displayErrorPopup: false
      })
    })
  }

  componentDidMount() {
    /**
     * Get container (form window) Height */
    const pusheableArea = document.getElementsByClassName("pushable")[0] as HTMLInputElement
    const height = pusheableArea.offsetHeight - CUSTOM_FULLSPAGE_DIFF

    if (height) {
      this.setState({ height })
    }

    /**
     * Append class to body tag (fixed overflow) */
    window.document.body.classList.add("fullpageSidebar")
  }

  componentWillUnmount() {
    window.document.body.classList.remove("fullpageSidebar")
  }

  render() {
    const {
      height,
      editEventWindow,
      displayInfoPopup,
      displayErrorPopup,
      displayEditionModal,
      currentSelectedEvent,
      events
    } = this.state

    const {
      currentSelectedWeek,
      currentWeekList,
      onSelectWeek,
      currentViewConfig,
      currentEventFilters
    } = this.props

    const filters: {
      criteria: string
      values: Array<{ id: string; label: string; defaultChecked: boolean }>
    } = { criteria: "", values: [] }
    if (currentViewConfig.name == VIEW_TYPES["INSTRUCTOR"] && currentEventFilters.length) {
      currentEventFilters.map((list: EventFilters) => {
        filters["criteria"] = list.filterCriteria
        list.values.map((filter: any) => filters.values.push({ ...filter, defaultChecked: true }))
      })
    }

    return (
      <>
        <TransitionablePortal
          open={displayEditionModal}
          transition={{ animation: "scale", duration: 100 }}
        >
          <div
            style={{
              position: "absolute",
              top: "52px",
              left: "0px",
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              zIndex: 9999
            }}
          >
            <EditModal
              event={currentSelectedEvent}
              onClose={this.eventHandlers.closeEditionModal}
              onEdit={(event: Event) => this.updateEventTimes(event)}
            />
          </div>
        </TransitionablePortal>
        <TransitionablePortal
          open={displayInfoPopup}
          transition={{ animation: "scale", duration: 101 }}
        >
          <InfoPupupWrapper posY={`${editEventWindow.posY}px`} posX={`${editEventWindow.posX}px`}>
            <InfoPopup
              event={currentSelectedEvent}
              onEditionModalOpen={this.eventHandlers.openEditionModal}
            />
            <a
              onClick={(e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) =>
                this.eventHandlers.closeInfoPopup(e)
              }
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                marginTop: "4px",
                marginRight: "8px",
                cursor: "pointer"
              }}
            >
              <img src={close__btn} alt="cerrar" width="9" height="auto" />
            </a>
          </InfoPupupWrapper>
        </TransitionablePortal>
        <TransitionablePortal
          open={displayErrorPopup}
          transition={{ animation: "scale", duration: 101 }}
        >
          <ErrorPopupWrapper posY={`${editEventWindow.posY}px`} posX={`${editEventWindow.posX}px`}>
            <ErrorPopup
              onEdit={(event: Event) => this.updateEventTimes(event)}
              onUndo={(event: Event) => this.undoPosition(event)}
              event={currentSelectedEvent}
            />
            <a
              onClick={(e: React.MouseEvent<HTMLElement | HTMLAnchorElement>) =>
                this.eventHandlers.closeErrorPopup(e)
              }
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                marginTop: "4px",
                marginRight: "8px",
                cursor: "pointer"
              }}
            >
              <img src={close__btn} alt="cerrar" width="9" height="auto" />
            </a>
          </ErrorPopupWrapper>
        </TransitionablePortal>

        <div className="container-fluid" style={{ padding: "0px !important" }}>
          <div className="row" style={{ height: "100vh" }}>
            <div
              className="col"
              style={{ backgroundColor: "#F2F2F2", padding: "2em", maxWidth: "293px" }}
            >
              <h3 style={{ fontWeight: "normal" }}>Semanas</h3>
              <WeeksViewer
                onSelectWeek={onSelectWeek}
                weeks={currentWeekList}
                selected={currentSelectedWeek}
                gridColumns={10}
              />
              {currentViewConfig.name === VIEW_TYPES["INSTRUCTOR"] && filters.values.length && (
                <>
                  <CategoriesFilter
                    categoriesList={filters.values}
                    filterCriteria={filters.criteria}
                    onFilterCategories={this.eventHandlers.filterCategories}
                  />

                  {this.props.currentCalendarRestrictions.length > 0 && (
                    <RestrictedAreasFilter
                      restrictions={this.props.currentCalendarRestrictions}
                      onFilterRestrictedAreas={this.eventHandlers.onFilterRestrictedAreas}
                    />
                  )}
                </>
              )}
            </div>
            <div className="col" style={{ marginTop: "-65px" }}>
              {!height || !events ? (
                <Loader active inline="centered" />
              ) : (
                <Suspense fallback={<Loader active inline="centered" />}>
                  <SchedulerLazyComponent
                    //initialConfig={this.initialConfig}
                    eventHandlers={this.eventHandlers}
                    containerHeight={height}
                    events={events}
                    restrictions={this.props.currentCalendarRestrictions}
                    dateToDisplay={(() => {
                      if (currentSelectedWeek && currentSelectedWeek.startingDate) {
                        return currentSelectedWeek.startingDate
                      } else {
                        return this.props.currentEventList[0].start
                      }
                    })()}
                  />
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentSectionInfo: state.canvasEditor.currentSectionInfo,
    currentWeekList: state.canvasEditor.currentWeekList,
    currentSelectedWeek: state.canvasEditor.currentSelectedWeek,
    currentEventList: state.canvasEditor.currentEventList,
    currentEventFilters: state.canvasEditor.currentEventFilters,
    currentViewConfig: state.canvasEditor.currentViewConfig,
    currentCalendarRestrictions: state.canvasEditor.currentCalendarRestrictions,
    currentModuleConfig: state.config.moduleConfig
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerContainer)
