import React, { Component, Props } from "react"
import { Route, Switch, withRouter, RouteComponentProps } from "react-router-dom"
import Login from "./modules/session/Login"
import Logout from "./modules/session/Logout"
import Shell from "./common/components/shell"
import { AuthProvider } from "./common/contexts/AuthContext"

import fakeAuth from "./authClient"

interface AppProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class App extends Component<AppProps> {
  render() {
    const isAuthenticated = fakeAuth.isAuthenticated()
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/" component={Shell} />
      </Switch>
    )
  }
}

export default withRouter(App)
