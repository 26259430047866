import React, { Component } from "react"
import { connect } from "react-redux"
import { get } from "lodash"
import { InfoSingleBox } from "./InfoSingleBox"
import { VIEW_SETTINGS_VALUES } from "../../../../commonTypes"

const mapStateToProps = (state: any) => ({
  info: state.canvasEditor.currentSectionInfo,
  viewConfig: state.canvasEditor.currentViewConfig
})
class SectionInfo extends Component<any, any> {
  state = {
    hiddenInfoOpen: false,
    hiddenButtonText: "+ mostrar más"
  }

  toggleHidddenInfo = () => {
    this.setState({
      hiddenInfoOpen: !this.state.hiddenInfoOpen,
      hiddenButtonText: !this.state.hiddenInfoOpen ? "- mostrar menos" : "+ mostrar más"
    })
  }

  render() {
    const { info, viewConfig } = this.props
    const { hiddenInfoOpen } = this.state
    return (
      <div className="col" style={{ height: hiddenInfoOpen ? "auto" : "62px", maxWidth: "800px" }}>
        <div style={{ height: "60px", padding: "0px", margin: "0px" }}>
          <div style={{ display: "inline-block", width: "60px", height: "60px", float: "left" }}>
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "25px",
                backgroundColor: "grey",
                fontSize: "1.7em",
                color: "white",
                lineHeight: "2.2em",
                padding: "0px",
                textAlign: "center",
                letterSpacing: "1px"
              }}
            >
              {info[(VIEW_SETTINGS_VALUES as any)[viewConfig.name].headerData.avatarLabel.accessor]}
            </div>
          </div>
          <div style={{ display: "inline-block", height: "60px" }}>
            <span style={{ display: "block", color: "#828282" }}>
              {
                info[
                  (VIEW_SETTINGS_VALUES as any)[viewConfig.name].headerData.beforeTitleLabel
                    .accessor
                ]
              }
            </span>
            <h1
              style={{
                display: "block",
                color: "#000",
                fontSize: "1.7em",
                fontWeight: 100,
                padding: "0px",
                margin: "0px"
              }}
            >
              {info[(VIEW_SETTINGS_VALUES as any)[viewConfig.name].headerData.titleLabel.accessor]}
            </h1>
          </div>
        </div>
        <div
          className="row"
          style={{
            paddingLeft: "60px",
            paddingTop: "5px",
            paddingBottom: "5px",
            display: !hiddenInfoOpen ? "none" : "flex"
          }}
        >
          {(VIEW_SETTINGS_VALUES as any)[viewConfig.name].headerData.columns.map(
            (item: any, index: number) => (
              <div
                className="col"
                style={{
                  minWidth: "100px",
                  maxWidth: item.width ? item.width : "100px",
                  marginBottom: "1em"
                }}
                key={`item___${index}`}
              >
                <InfoSingleBox title={item.label} content={get(info, item.accessor)} />
              </div>
            )
          )}
        </div>
        <span
          style={{ cursor: "pointer", color: "#2273B5", marginLeft: "60px" }}
          onClick={() => this.toggleHidddenInfo()}
        >
          {" "}
          {this.state.hiddenButtonText}{" "}
        </span>
      </div>
    )
  }
}

export default connect(mapStateToProps)(SectionInfo)
