import {
  Event,
  Week,
  WeekList,
  EventErrorStatusChangeAction,
  SchedulerActionsTypes,
  ViewConfig,
  EventFilters,
  SET_ERROR_STATUS,
  SET_CURRENT_SECTION_INFO,
  SET_NEW_EVENTS_POSITION,
  SET_CURRENT_EVENT_LIST,
  SET_CURRENT_WEEK_LIST,
  SET_CURRENT_SELECTED_WEEK,
  SET_CURRENT_SELECTED_WEEK_BY_POSITION,
  SET_CURRENT_VIEW_CONFIG,
  SET_CURRENT_EVENT_FILTERS,
  SET_CURRENT_CALENDAR_RESTRICTIONS,
  CalendarRestriction
} from "./types"

export function setSectionInfo(sectionInfo: any): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_SECTION_INFO,
    payload: sectionInfo
  }
}

export function setEventList(newEventList: Array<Event>): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_EVENT_LIST,
    payload: newEventList
  }
}

export function updateErrorStatus(newEventStatus: any): SchedulerActionsTypes {
  return {
    type: SET_ERROR_STATUS,
    payload: newEventStatus
  }
}

export function onUpdateLocalPositions(
  newEventList: Array<Event>,
  currentEvent: Event
): SchedulerActionsTypes {
  return {
    type: SET_NEW_EVENTS_POSITION,
    payload: { eventList: newEventList, event: currentEvent }
  }
}

export function setWeekList(newWeekList: WeekList): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_WEEK_LIST,
    payload: newWeekList
  }
}

export function setCurrentWeek(week: Week): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_SELECTED_WEEK,
    payload: week
  }
}

export function setCurrentWeekByPosition(position: string): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_SELECTED_WEEK_BY_POSITION,
    payload: position
  }
}

export function setCurrentViewConfig(viewConfig: ViewConfig): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_VIEW_CONFIG,
    payload: viewConfig
  }
}

export function setCurrentEventFilter(eventFilter: EventFilters): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_EVENT_FILTERS,
    payload: eventFilter
  }
}

export function setCurrentCalentarRestrictions(
  restrictions: Array<CalendarRestriction>
): SchedulerActionsTypes {
  return {
    type: SET_CURRENT_CALENDAR_RESTRICTIONS,
    payload: restrictions
  }
}
