import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import configureStore, { history } from "./redux/configureStore"
import { ApolloProvider } from "react-apollo"
import ApolloClient from "apollo-boost"
import * as serviceWorker from "./serviceWorker"

import App from "./App"

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_PATH
})

const store = configureStore()

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/* place ConnectedRouter under Provider */}
        <>
          {/* your usual react-router v4/v5 routing */}
          <App />
        </>
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>,

  document.getElementById("root")
)

serviceWorker.unregister()
