import gql from "graphql-tag"

const GET_ROOMS_QUERY = gql`
  query classRoomSearch($scenarioId: ID!, $originId: ID!, $filter: ClassroomSearchInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorClassroom(filter: $filter) {
        items {
          id
          hash
          code
          name
          classroomType {
            id
            code
            name
          }
          building {
            id
            code
            name
          }
          capacity
          allowedOverflow
          enabled
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

const GET_INSTRUCTOR_QUERY = gql`
  query instructorSearch($scenarioId: ID!, $originId: ID!, $filter: InstructorSearchInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorInstructors(filter: $filter) {
        items {
          id
          code
          name
          priority
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

export { GET_ROOMS_QUERY, GET_INSTRUCTOR_QUERY }
