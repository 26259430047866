import {
  SET_CURRENT_ORIGIN_ID,
  SET_CURRENT_SCENARIO_ID,
  SET_CURRENT_CONFIG,
  ModuleConfigActionsTypes,
  ConfigItemState
} from "./types"

const initialState: ConfigItemState = {
  moduleConfig: {
    originId: 198,
    scenarioId: 14
  }
}

export default function(state = initialState, action: ModuleConfigActionsTypes): ConfigItemState {
  switch (action.type) {
    case SET_CURRENT_ORIGIN_ID: {
      return {
        ...state,
        moduleConfig: { ...state.moduleConfig, originId: action.payload }
      }
    }
    case SET_CURRENT_SCENARIO_ID: {
      return {
        ...state,
        moduleConfig: { ...state.moduleConfig, scenarioId: action.payload }
      }
    }
    case SET_CURRENT_CONFIG: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
