export interface ConfigItem {
  originId: number
  scenarioId: number
}

export interface ConfigItemState {
  moduleConfig: ConfigItem
}

export const SET_CURRENT_ORIGIN_ID = "SET_CURRENT_ORIGIN_ID"
export const SET_CURRENT_SCENARIO_ID = "SET_CURRENT_SCENARIO_ID"
export const SET_CURRENT_CONFIG = "SET_CURRENT_CONFIG"

interface SetCurrentOriginIdAction {
  type: typeof SET_CURRENT_ORIGIN_ID
  payload: number
}

interface SetCurrentScenarioIdAction {
  type: typeof SET_CURRENT_SCENARIO_ID
  payload: number
}

interface SetCurrentConfigAction {
  type: typeof SET_CURRENT_CONFIG
  payload: ConfigItem
}

export type ModuleConfigActionsTypes =
  | SetCurrentOriginIdAction
  | SetCurrentScenarioIdAction
  | SetCurrentConfigAction
