import { combineReducers } from "redux"
import { History } from "history"
import { connectRouter } from "connected-react-router"
import contextSearch from "../common/components/layout/FullPageSidebar/components/ContextSearch/redux/reducers"
import canvasEditor from "../modules/scheduler/pages/canvas/redux/reducers"
import moduleConfig from "../modules/scheduler/redux/reducers"

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    config: moduleConfig,
    contextSearch: contextSearch,
    canvasEditor: canvasEditor
  })
