import React from "react"
import { Grid, Segment, Icon } from "@foris/foris-ui"
import styled from "styled-components"

const DAY_NAMES: any = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo"
}

const SegmentWrapper = styled.div`
  .mainTitle__container {
    width: 450px;
    display: inline;
  }
  p.mainTitle {
    font-size: 1.1em;
    max-width: 88%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-top: 2px;
    text-overflow: ellipsis;
    float: left;
    margin-right: 5px;
  }
`

const InfoRow = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 1.6em;
`

const InfoColumn = styled.div`
  display: inline-block;
  min-width: 3em;
  margin-right: 1em;
  &.large {
    width: 115px;
    & > div {
      line-height: 1.1em;
    }
  }
  &.ico__column {
    margin-right: 0;
  }
`

const InfoItem = styled.div`
  display: inline;
  & > label {
    display: block;
    color: #828282;
    font-size: 12px;
  }
  & > span {
    display: inline;
    color: #333;
    font-weight: 400;
  }
  .text__ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-top: 2px;
    text-overflow: ellipsis;
    &.main__title {
      color: #2974b6;
    }
  }
`

const InfoPopup = (props: any) => {
  const { event } = props
  const instructor = event.resource.instructor ? event.resource.instructor : {}
  const classroom = event.resource.classroom ? event.resource.classroom : { classroomType: {} }

  return (
    <SegmentWrapper>
      <Segment style={{ padding: "1.8em" }}>
        <Grid>
          <div className="mainTitle__container">
            <p className="mainTitle">{event.title} &nbsp;&nbsp;</p>
            <a style={{ cursor: "pointer" }} onClick={e => props.onEditionModalOpen(e)}>
              <Icon style={{ fontSize: "0.9em", color: "#BDBDBD" }} name="pencil alternate" />
            </a>
          </div>
          <InfoRow>
            <InfoColumn className="ico__column">
              <Icon style={{ fontSize: "1.5em", color: "#BDBDBD" }} name="clock outline" />
            </InfoColumn>
            <InfoColumn className="large">
              <InfoItem>
                <label>Día</label>
                <span className="text__ellipsis main__title">
                  {DAY_NAMES[event.resource.blockRange.start.day]}
                </span>
              </InfoItem>
            </InfoColumn>
            <InfoColumn>
              <InfoItem>
                <label>Horario</label>
                <span
                  style={{ fontWeight: 500 }}
                >{`${event.resource.blockRange.start.startingTime.replace(
                  ":00",
                  ""
                )} - ${event.resource.blockRange.end.endingTime.replace(":00", "")}`}</span>
              </InfoItem>
            </InfoColumn>
          </InfoRow>

          <InfoRow>
            <InfoColumn className="ico__column">
              <Icon
                style={{ fontSize: "1.5em", color: "#BDBDBD" }}
                name="calendar alternate outline"
              />
            </InfoColumn>
            <InfoColumn className="large">
              <InfoItem>
                <label>Asignatura</label>
                <span className="text__ellipsis main__title">{`${event.resource.info.course.code} ${event.resource.info.course.name}`}</span>
              </InfoItem>
            </InfoColumn>
            <InfoColumn>
              <InfoItem>
                <label>Comp</label>
                <span style={{ fontWeight: 500 }}>
                  {event.resource.info.courseComponent.component.code}
                </span>
              </InfoItem>
            </InfoColumn>
            <InfoColumn>
              <InfoItem>
                <label>Sección</label>
                <span style={{ fontWeight: 500 }}>{event.resource.info.section.id}</span>
              </InfoItem>
            </InfoColumn>
            <InfoColumn>
              <InfoItem>
                <label>Vac</label>
                <span style={{ fontWeight: 500 }}>{event.resource.info.section.vacancies}</span>
              </InfoItem>
            </InfoColumn>
          </InfoRow>

          <InfoRow>
            <InfoColumn className="ico__column">
              <Icon style={{ fontSize: "1.5em", color: "#BDBDBD" }} name="user outline" />
            </InfoColumn>
            <InfoColumn className="large">
              <InfoItem>
                <label>Docente</label>
                <span className="text__ellipsis main__title">
                  {instructor.name ? instructor.name : "-"}
                </span>
              </InfoItem>
            </InfoColumn>
            {instructor.code && (
              <InfoColumn>
                <InfoItem>
                  <label>Código</label>
                  <span className="text__ellipsis" style={{ fontWeight: 500 }}>
                    {instructor.code ? (
                      instructor.code
                    ) : (
                      <span style={{ color: "inherit" }}>-</span>
                    )}
                  </span>
                </InfoItem>
              </InfoColumn>
            )}
          </InfoRow>

          <InfoRow>
            <InfoColumn className="ico__column">
              <Icon style={{ fontSize: "1.5em", color: "#BDBDBD" }} name="building outline" />
            </InfoColumn>
            <InfoColumn className="large">
              <InfoItem>
                <label>Sala</label>
                <span className="text__ellipsis main__title">
                  {classroom.name ? (
                    `${classroom.name} ${classroom.classroomType.name}`
                  ) : (
                    <span style={{ color: "inherit" }}>-</span>
                  )}
                </span>
              </InfoItem>
            </InfoColumn>
            {classroom.code && (
              <InfoColumn>
                <InfoItem>
                  <label>Código</label>
                  <span className="text__ellipsis" style={{ fontWeight: 500 }}>
                    {classroom.code ? classroom.code : "-"}
                  </span>
                </InfoItem>
              </InfoColumn>
            )}
            {classroom.classroomType.code && (
              <InfoColumn>
                <InfoItem>
                  <label>Tipo</label>
                  <span className="text__ellipsis" style={{ fontWeight: 500, maxWidth: "66px" }}>
                    {classroom.classroomType.code ? classroom.classroomType.code : "-"}
                  </span>
                </InfoItem>
              </InfoColumn>
            )}
            {classroom.code && (
              <InfoColumn>
                <InfoItem>
                  <label>Edificio</label>
                  <span className="text__ellipsis" style={{ fontWeight: 500 }}>
                    {classroom.code ? classroom.code : "-"}
                  </span>
                </InfoItem>
              </InfoColumn>
            )}
          </InfoRow>
        </Grid>
      </Segment>
    </SegmentWrapper>
  )
}

export default InfoPopup
