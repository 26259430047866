import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { setFilter, setSearchCriteria, setCurrentSearchResultsList } from "./redux/actions"
import { Filter, ContextSearchState } from "./redux/types"
import { useApolloClient } from "react-apollo"
import { ContextSearch } from "@foris/foris-ui"
import options from "./options"

import {
  COURSES_QUERY,
  PACKAGES_QUERY,
  POPULATIONS_QUERY,
  SECTIONS_QUERY,
  INSTRUCTORS_QUERY,
  CLASSROOMS_QUERY
} from "./queries"

const GET_RESULTS_COURSE = COURSES_QUERY
const GET_RESULTS_PACKAGE = PACKAGES_QUERY
const GET_RESULTS_POPULATION = POPULATIONS_QUERY
const GET_RESULTS_SECTION = SECTIONS_QUERY
const GET_RESULTS_INSTRUCTOR = INSTRUCTORS_QUERY
const GET_RESULTS_CLASSROOM = CLASSROOMS_QUERY

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeUrl: (value: string) => dispatch(push(value)),
    onSelectNewFilter: (filter: Filter) => {
      dispatch(setFilter(filter))
    },
    onSetSearchCriteria: (criteria: string) => {
      dispatch(setSearchCriteria(criteria))
    },
    onReceiveResultList: (resultsList: any) => {
      dispatch(setCurrentSearchResultsList(resultsList))
    }
  }
}

function usePrevious(value: any): any {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const ContextSearchLocalInstance: React.FC = (props: any) => {
  const {
    onSelectNewFilter,
    onSetSearchCriteria,
    onReceiveResultList,
    currentFilter,
    currentSearchCriteria,
    currentResultList,
    currentModuleConfig
  } = props

  const client = useApolloClient()
  const [results, setResults] = useState(null)
  const prevResults = usePrevious({ results, setResults })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (results !== null && prevResults.results !== results) {
      onReceiveResultList(results)
    }
  }, [results, setResults])

  const goToResultsPage = (value: any) => {
    const paramName: any = {
      Course: "course",
      Section: "section",
      Population: "population",
      Package: "package",
      Instructor: "instructor",
      Classroom: "classroom"
    }

    if (paramName.hasOwnProperty(value.__typename))
      props.changeUrl(`/scheduler/editor/search/?${paramName[value.__typename]}=${value.id}`)
  }

  return (
    <ContextSearch
      isLoading={isLoading}
      onSelectResult={(value: string) => {
        goToResultsPage(value)
      }}
      onSelectedOption={(filter: Filter) => {
        onSelectNewFilter(filter)
      }}
      onEmitSearchCriteriaOnEnter={(searchCriteria: any) => {
        onSetSearchCriteria(searchCriteria)
      }}
      onEmitSearchResultsObservable={async (searchCriteria: any) => {
        if (currentFilter && currentFilter.type) {
          onSetSearchCriteria(searchCriteria)
          setIsLoading(true)
          const { data } = await client.query({
            query: eval(`GET_RESULTS_${currentFilter.type.toUpperCase()}`),
            variables: {
              originId: currentModuleConfig.originId,
              scenarioId: currentModuleConfig.scenarioId,
              filter: {
                page: 1,
                size: 50,
                searchTerm: searchCriteria
              }
            }
          })
          setResults(data.cube)
          setIsLoading(false)
        }
      }}
      options={options}
      inputProps={{ placeholder: "Buscar..." }}
      currentFilter={
        currentFilter && currentFilter.label && currentFilter.type ? currentFilter : null
      }
      currentSearchCriteria={currentSearchCriteria}
      currentResultList={currentResultList}
    />
  )
}

const mapStateToProps = (state: any) => {
  const contextSearch: ContextSearchState = state.contextSearch
  return {
    currentFilter: contextSearch.currentFilter,
    currentSearchCriteria: contextSearch.currentSearchCriteria,
    currentResultList: contextSearch.currentResultList,
    currentModuleConfig: state.config.moduleConfig
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextSearchLocalInstance)
