import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import queryString from "query-string"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import styled from "styled-components"
import { Grid, Icon, Loader } from "@foris/foris-ui"

import ReactTable from "react-table"
import "react-table/react-table.css"

import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"

const LoadingWrapper = styled.div`
  text-align: center;
  padding: 3em;
`

const TableWrapper = styled.div`
  padding: 3em;
  e: 13px;
  & > h1 {
    font-size: 1.2em;
    font-weight: normal;
  }
  & > .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    height: 40px;
    line-height: 30px;
  }
  & > .ReactTable .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }
  & > .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 10px 5px;
  }
`

const RESULTS_QUERY = gql`
  query editorSearchResult($scenarioId: ID!, $originId: ID!, $filter: EditorSearchInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorSearch(filter: $filter) {
        items {
          id
          vacancies
          course {
            name
            curriculum {
              code
              name
            }
          }
          sessions {
            assignment {
              classroom {
                id
                code
              }
              instructor {
                code
                name
              }
            }
          }
          schedule {
            summaryByDay {
              day
              range {
                start
                end
              }
            }
            resourceAssigment {
              schedule
              classroom
              instructor
            }
          }
        }
        pageInfo {
          total
          hasPreviousPage
          hasNextPage
          page
          size
        }
      }
    }
  }
`

const columns = [
  {
    Header: "Asignatura",
    accessor: "course.name",
    Cell: (props: any) => <span className="number">{props.value}</span>,
    minWidth: 200
  },
  {
    Header: "Sec",
    accessor: "id",
    width: 70,
    Cell: (props: any) => <div style={{ textAlign: "center" }}>{props.value}</div>
  },
  {
    Header: (props: any) => <span>Vac</span>, // Custom header components!
    accessor: "vacancies",
    width: 40
  },
  {
    Header: "Lunes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "MONDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Martes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "TUESDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Miércoles",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "WEDNESDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Jueves",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "THURSDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Viernes",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "FRIDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Sábado",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "SATURDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "Domingo",
    accessor: "schedule.summaryByDay",
    Cell: (props: any) => {
      const hasEvent = props.value.filter((item: any) => item.day === "SUNDAY")
      if (hasEvent.length) {
        return (
          <div style={{ textAlign: "center" }}>{`${hasEvent[0].range.start.replace(
            ":00",
            ""
          )} - ${hasEvent[0].range.end.replace(":00", "")}`}</div>
        )
      } else {
        return <div />
      }
    },
    width: 99
  },
  {
    Header: "H",
    accessor: "schedule.resourceAssigment.schedule",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  },
  {
    Header: "S",
    accessor: "schedule.resourceAssigment.classroom",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  },
  {
    Header: "D",
    accessor: "schedule.resourceAssigment.instructor",
    Cell: (props: any) => (
      <div style={{ textAlign: "center" }}>
        {props.value ? <Icon name="check" /> : <Icon name="close" />}
      </div>
    ),
    width: 45
  }
]

interface TableContainerVars {
  scenarioId: number
  originId: number
  filter?: { page?: number; packageIds?: any }
}

const TableContainer: React.FC<{
  resourceType: string
  resourceIds: Array<any> | undefined
  changeUrl: Function
  currentSearchCriteria: any
  currentModuleConfig: any
}> = ({ resourceType, resourceIds, changeUrl, currentSearchCriteria, currentModuleConfig }) => {
  const { loading, error, data } = useQuery<any, TableContainerVars>(RESULTS_QUERY, {
    variables: {
      scenarioId: currentModuleConfig.scenarioId,
      originId: currentModuleConfig.originId,
      filter: {
        page: 1,
        [resourceType]: resourceIds ? resourceIds : null
      }
    },
    fetchPolicy: "network-only"
  })

  if (loading)
    return (
      <LoadingWrapper>
        <Loader active />
      </LoadingWrapper>
    )
  if (error) return <p>{`Error! ${error}`}</p>

  const tableData = data && data.cube ? data.cube.editorSearch.items : [] || []

  return (
    <TableWrapper>
      {data.cube.editorSearch.items.length ? (
        <>
          <h1>{`${data.cube.editorSearch.items.length} ${
            data.cube.editorSearch.items.length > 1 ? "resultados" : "resultado"
          } para: ${currentSearchCriteria}`}</h1>
          <ReactTable
            data={tableData}
            columns={columns}
            className="-striped -highlight"
            minRows={1}
            showPagination={false}
            getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                onDoubleClick: (e: any) => {
                  if (
                    e.target.className === "rt-td rt-expandable" ||
                    e.target.className === "rt-expander" ||
                    e.target.className === "rt-expander -open"
                  )
                    return
                  changeUrl(`/scheduler/editor/section/${rowInfo.row.id}`)
                }
              }
            }}
            SubComponent={(row: any) => {
              console.log("row.original", row.original)
              return (
                <div style={{ padding: "0 0 0 2.5em" }}>
                  <div style={{ display: "block", padding: "1.5em 0" }}>
                    <div style={{ display: "inline-block", marginRight: "2em" }}>
                      <div style={{ display: "block", fontSize: "0.9em" }}>Currículo</div>
                      <div style={{ display: "block" }}>
                        {`${row.original.course.curriculum.code} ${
                          row.original.course.curriculum.name
                        }`}
                      </div>
                    </div>
                    <div style={{ display: "inline-block", marginRight: "2em" }}>
                      <div style={{ display: "block", fontSize: "0.9em" }}>Salas</div>
                      <div style={{ display: "block" }}>
                        {(() => {
                          const classrooms: any = []
                          row.original.sessions.map((item: any) => {
                            if (item.assignment.classroom)
                              classrooms.push(item.assignment.classroom.code)
                          })

                          const uniqueClassrooms = classrooms.filter(function(
                            classroom: any,
                            index: number
                          ) {
                            return classroom.indexOf(classroom) >= index
                          })
                          const values = uniqueClassrooms.length
                            ? uniqueClassrooms.join().replace(",", ", ")
                            : "-"

                          return values
                        })()}
                      </div>
                    </div>
                    <div style={{ display: "inline-block", marginRight: "2em" }}>
                      <div style={{ display: "block", fontSize: "0.9em" }}>Docente</div>
                      <div style={{ display: "block" }}>
                        {(() => {
                          const instructors: any = []
                          row.original.sessions.map((item: any) => {
                            if (item.assignment.instructor)
                              instructors.push(
                                `${item.assignment.instructor.code}, ${
                                  item.assignment.instructor.name
                                }`
                              )
                          })
                          const uniqueInstructors = instructors.filter(function(
                            instructor: any,
                            index: number
                          ) {
                            return instructor.indexOf(instructor) >= index
                          })
                          const values = uniqueInstructors.length ? uniqueInstructors[0] : "-"
                          return values
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </>
      ) : (
        <>
          <p>No hemos encontrado resultados asociados a tu búsqueda.</p>
          <h4>Algunos consejos:</h4>
          <ul>
            <li>¿Escribiste el código de la sección?</li>
            <li>¿El recurso que estás buscando está programado en esta simulación?</li>
          </ul>
        </>
      )}
    </TableWrapper>
  )
}

const Results: React.FC = ({
  pathname,
  search,
  hash,
  push,
  currentSearchCriteria,
  currentModuleConfig
}: any) => {
  let resourceType
  let resourceIds

  const params: any = queryString.parse(search)
  const queryParamName: any = {
    course: "courseIds",
    section: "sectionIds",
    population: "populationIds",
    package: "packageIds",
    instructor: "instructorIds",
    classroom: "classroomIds"
  }

  if (Object.keys(params) && queryParamName.hasOwnProperty(Object.keys(params)[0])) {
    resourceType = queryParamName[Object.keys(params)[0]]
    resourceIds = [Object.values(params)[0]]
  }

  return (
    <FullPageSidebar displayContextSearch={true}>
      <Grid.Row>
        {resourceType && resourceIds ? (
          <TableContainer
            changeUrl={(value: string) => push(value)}
            resourceType={resourceType}
            resourceIds={resourceIds}
            currentSearchCriteria={currentSearchCriteria}
            currentModuleConfig={currentModuleConfig}
          />
        ) : (
          <p>Ha ocurrido un error, inténtalo nuevamente</p>
        )}
      </Grid.Row>
    </FullPageSidebar>
  )
}

const mapStateToProps = (state: any) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  currentSearchCriteria: state.contextSearch.currentSearchCriteria,
  currentModuleConfig: state.config.moduleConfig
})

export default connect(
  mapStateToProps,
  { push }
)(Results)
