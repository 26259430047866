import React, { Component } from "react"
import styled from "styled-components"

const SingleBox = styled.div`
  color: #333;
  & > span {
    display: block;
    font-weight: 500;
    max-width: 100%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.title {
      font-size: 12px;
      color: #828282;
    }
  }
`
const InfoSingleBox: React.FC<{ title: string; content: string }> = ({ ...props }) => {
  const { title, content } = props
  return (
    <SingleBox>
      <span className="title">{title}</span>
      <span>{content}</span>
    </SingleBox>
  )
}
export { InfoSingleBox }
