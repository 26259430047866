import React from "react"
import { Checkbox } from "@foris/foris-ui"
import styled from "styled-components"

const ListContainer = styled.div`
  margin-bottom: 1.5em;
  & > h3 {
    font-size: 16px;
    font-weight: 400;
  }
`

const ListItem = styled.div`
  display: block;
  margin-bottom: 0px;
  & > div > div > label {
    max-width: 218px;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
   {
    .ui.checked.checkbox,
    .ui.checkbox {
      label {
        &:before {
          background-color: #6ac0c5 !important;
        }
      }
    }
  }
  &.category___0 {
    .ui.checked.checkbox,
    .ui.checkbox {
      label {
        &:before {
          background-color: #6ac0c5 !important;
        }
      }
    }
  }
  &.category___1 {
    .ui.checked.checkbox,
    .ui.checkbox {
      label {
        &:before {
          background-color: #2e9499 !important;
        }
      }
    }
  }
  &.category___2 {
    .ui.checked.checkbox,
    .ui.checkbox {
      label {
        &:before {
          background-color: #6cbe9d !important;
        }
      }
    }
  }
  &.category___3 {
    .ui.checked.checkbox,
    .ui.checkbox {
      label {
        &:before {
          background-color: #226e72 !important;
        }
      }
    }
  }
`

const CheckboxWrapper = styled.div`
  .ui.checked,
  .ui.checked.checkbox {
    label {
      &:after {
        content: "";
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='50' height='50'%0AviewBox='0 0 50 50'%0Astyle=' fill:%23ffffff;'%3E%3Cpath d='M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
`

const CategoriesFilter: React.SFC<{
  categoriesList: any
  onFilterCategories: Function
  filterCriteria: string
}> = ({ categoriesList, onFilterCategories, filterCriteria }: any) => {
  return (
    <ListContainer>
      <h3>Secciones</h3>
      <div style={{ maxHeight: 100, maxWidth: "100%", overflowY: "auto" }}>
        {categoriesList.map((category: any, index: number) => (
          <ListItem
            key={`category___${category.id}`}
            className={`category___${category.styles.categoryID}`}
          >
            <CheckboxWrapper>
              <Checkbox
                defaultChecked={category.defaultChecked}
                id={category.id}
                filtercriteria={filterCriteria}
                onChange={onFilterCategories}
                label={{ children: category.label }}
              />
            </CheckboxWrapper>
          </ListItem>
        ))}
      </div>
    </ListContainer>
  )
}
interface RestrictedAreasProps {
  restrictions: any
  onFilterRestrictedAreas: Function
}

const RestrictedAreasFilter: React.SFC<RestrictedAreasProps> = ({
  restrictions,
  onFilterRestrictedAreas
}: any) => {
  return (
    <ListContainer>
      <h3>Restricciones</h3>
      <div style={{ maxHeight: 100, maxWidth: "100%", overflowY: "auto" }}>
        {restrictions.map((item: any, index: number) => (
          <ListItem key={`area___${item.type}`}>
            <CheckboxWrapper>
              <Checkbox
                defaultChecked={true}
                areatype={item.type}
                onChange={onFilterRestrictedAreas}
                label={{ children: item.label }}
              />
            </CheckboxWrapper>
          </ListItem>
        ))}
      </div>
    </ListContainer>
  )
}

export { CategoriesFilter, RestrictedAreasFilter }
