import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import { connect } from "react-redux"
import { Tab, MenuItem, Loader, Icon } from "@foris/foris-ui"
import { cloneDeep, groupBy as _groupBy } from "lodash"

import { SectionInfo, ListTab } from "./components"
import { SchedulerContainer } from "./components/SchedulerTab"
import FullPageSidebar from "../../../../common/components/layout/FullPageSidebar"
import {
  setEventList,
  setSectionInfo,
  setWeekList,
  setCurrentViewConfig,
  setCurrentEventFilter,
  setCurrentWeek,
  setCurrentCalentarRestrictions
} from "./redux/actions"

import {
  Event,
  Week,
  WeekList,
  ViewConfig,
  EventFilters,
  EVENT_STATES,
  VIEW_TYPES,
  FILTER_TYPES,
  CalendarRestriction
} from "./redux/types"

import "../../../../grid__css_poc/bootstrap-grid.min.css"

import { SECTION_QUERY_SECTION, SECTION_QUERY_INSTRUCTOR, UPDATE_EVENT_MUTATION } from "./queries"

const SECTION_QUERY = SECTION_QUERY_SECTION
const INSTRUCTOR_QUERY = SECTION_QUERY_INSTRUCTOR

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetViewConfig: (viewConfig: ViewConfig) => {
      dispatch(setCurrentViewConfig(viewConfig))
    },
    onSetWeekList: (newWeekList: WeekList) => {
      dispatch(setWeekList(newWeekList))
    },
    onSelectWeek: (selectedWeek: Week) => {
      dispatch(setCurrentWeek(selectedWeek))
    },
    onSetEventList: (eventList: any) => {
      dispatch(setEventList(eventList))
    },
    onSetEventFilters: (filters: EventFilters) => {
      dispatch(setCurrentEventFilter(filters))
    },
    onSetSectionInfo: (sectionInfo: any) => {
      dispatch(setSectionInfo(sectionInfo))
    },
    onSetCalendarRestrictions: (restrictions: Array<CalendarRestriction>) => {
      dispatch(setCurrentCalentarRestrictions(restrictions))
    }
  }
}

const SchedulerHOCWrapper: React.FC<any> = () => {
  const [updateEvents] = useMutation(UPDATE_EVENT_MUTATION)

  const callUpdateHook = (variables: any, callback: Function) => {
    updateEvents({ variables })
      .then(data => callback({ status: "OK", content: data }))
      .catch(err => callback({ status: "ERROR", content: err }))
  }
  return (
    <SchedulerContainer
      updateEvent={(variables: any, callback: Function) => callUpdateHook(variables, callback)}
    />
  )
}

const panes = [
  {
    menuItem: (
      <MenuItem key="scheduler" style={{ marginLeft: "2em" }}>
        <Icon name="calendar alternate outline" />
      </MenuItem>
    ),
    render: () => <SchedulerHOCWrapper />
  },
  {
    menuItem: (
      <MenuItem key="list">
        <Icon name="list" />
      </MenuItem>
    ),
    render: () => (
      <div className="row">
        <div className="container-fluid">
          <div className="col">
            <ListTab />
          </div>
        </div>
      </div>
    )
  }
]

interface SectionsVar {
  scenarioId: number
  originId: number
  resourceType: string
  resourceId: Array<number>
}

const Sections: React.FC<any> = ({
  // resourceId,
  onSetWeekList,
  onSelectWeek,
  onSetEventList,
  onSetViewConfig,
  onSetSectionInfo,
  onSetEventFilters,
  onSetCalendarRestrictions,
  currentModuleConfig,
  currentSectionInfo,
  currentViewConfig,
  viewConfig
}) => {
  const [readyToRender, setReadyToRender] = useState(false)
  const { loading, error, data, refetch } = useQuery<any, SectionsVar>(
    eval(`${viewConfig.name}_QUERY`),
    {
      variables: {
        scenarioId: currentModuleConfig.scenarioId,
        originId: currentModuleConfig.originId,
        resourceType: viewConfig.name,
        resourceId: viewConfig.id
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        if (data && data.cube) {
          serializeAndStoreData(data)
        }
      }
    }
  )

  function serializeAndStoreData(data: any) {
    const events: Array<Event> = []
    const eventFilters: Array<{
      type: string
      filterCriteria: string
      values: Array<{ id: string; label: string }>
    }> = []

    const weeks: WeekList = []
    const tmpFilterArray: Array<{
      id: string
      label: string
      styles: { categoryID: number }
    }> = []

    data.cube.editorView.sections.map((section: any, index: number) => {
      tmpFilterArray.push({
        id: section.id,
        label: `${section.id} ${section.sessions[0].section.course.name}`,
        styles: { categoryID: index }
      })

      section.sessions.map((session: any) => {
        session.scheduleEvent.map((block: any) => {
          events.push({
            sessionId: session.id,
            sectionId: section.id,
            styles: {
              categoryID: index
            },
            id: block.id,
            title: `${session.section.course.name} ${session.section.code} ${
              session.section.courseComponent.component.code
            }`,
            start: new Date(block.start),
            end: new Date(block.end),
            resource: {
              ...session.assignment,
              info: {
                section: {
                  id: section.id,
                  code: session.section.code,
                  vacancies: session.section.vacancies
                },
                course: session.section.course,
                courseComponent: session.section.courseComponent
              }
            },
            prevResource: cloneDeep(session.assignment),
            error: {
              status: false,
              details: null
            },
            state: EVENT_STATES.UNMODIFIED
          })
        })
      })
    })

    eventFilters.push({ type: "SECTION", filterCriteria: "sectionId", values: tmpFilterArray })

    data.cube.editorWeeks.map((item: any, index: number) => {
      weeks.push({
        id: item.id,
        value: (index + 1).toString(),
        status: parseInt(item.eventCount) ? "on" : "off",
        title: `${item.name}: ${item.startingDate}/${item.endingDate}`,
        endingDate: new Date(`${item.endingDate} 00:00:00`),
        startingDate: new Date(`${item.startingDate} 00:00:00`),
        eventCount: item.eventCount
      })
    })

    const initialSelectedWeek = weeks[weeks.findIndex((week: Week) => week.status === "on")]

    /**
     * Instructor SectionInfo Factory */
    const getSectionInfo = (viewConfigName: string, rawInfo: any) => {
      const obj: any = {}
      switch (viewConfigName) {
        case "INSTRUCTOR":
          obj["contractLabel"] = rawInfo.instructor.contract
          obj["priority"] = rawInfo.instructor.priority
          obj["blocksLabel"] = `${rawInfo.assignedBlocks} (${rawInfo.instructor.minBlocks}/${
            rawInfo.instructor.maxBlocks
          })`
          obj["sectionsLabel"] = `${rawInfo.assignedBlocks} (${rawInfo.instructor.minCourses}/${
            rawInfo.instructor.maxSections
          })`
          obj["coursesLabel"] = `${rawInfo.assignedSections} (${rawInfo.instructor.minCourses}/${
            rawInfo.instructor.maxCourseComponents
          })`
          obj["avatarStringLabel"] = `${rawInfo.instructor.name.substring(0, 2).toUpperCase()}`
          obj["beforeTitleLabel"] = rawInfo.instructor.code
          obj["titleLabel"] = rawInfo.instructor.name
          break

        case "SECTION":
          obj["stripeLabel"] = (() => {
            let stripeCodes: any = []
            rawInfo.courseComponent.stripePriorities.map((stripePriority: any) => {
              if (stripePriority.stripe) {
                stripeCodes.push(`${stripePriority.stripe.code}`)
              }
            })
            const uniqueStripe = stripeCodes.filter(function(stripe: any, index: number) {
              return stripe.indexOf(stripe) >= index
            })
            const values = uniqueStripe.length ? uniqueStripe[0] : "-"
            return values
          })()
          obj["classRoomTypeLabel"] = (() => {
            let classroomCodes: any = []
            rawInfo.courseComponent.classroomPriorities.map((classroomPriority: any) => {
              if (classroomPriority.classroomType) {
                classroomCodes.push(`${classroomPriority.classroomType.code}`)
              }
            })
            const uniqueClassroomType = classroomCodes.filter(function(
              classroom: any,
              index: number
            ) {
              return classroom.indexOf(classroom) >= index
            })
            const values = uniqueClassroomType.length ? uniqueClassroomType[0] : "-"
            return values
          })()
          obj["avatarStringLabel"] = rawInfo.course.name.substring(0, 2).toUpperCase()
          obj["beforeTitleLabel"] = `Sección: ${rawInfo.section.id} ${
            rawInfo.courseComponent.component.code
          }`
          obj["titleLabel"] = `${rawInfo.course.code} ${rawInfo.course.name}`
          break
      }

      return { ...rawInfo, ...obj }
    }

    let restrictionsByDay: any = []

    if (data.cube.editorRestrictions) {
      data.cube.editorRestrictions.map((item: any) => {
        const tmp: Array<CalendarRestriction> = []
        let label = ""

        item.scheduleEvent.map((r: any) => {
          tmp.push({
            id: r.id,
            dayId: r.dayId,
            start: r.start,
            end: r.end,
            defaultChecked: true
          })
        })
        switch (item.type) {
          case "INSTRUCTOR_AVAILABILITY":
            label = "Disponibilidad docente"
            break
        }

        restrictionsByDay.push({
          type: item.type,
          label: label,
          scheduleEvent: _groupBy(tmp, "dayId")
        })
      })
    }

    onSetCalendarRestrictions(restrictionsByDay)
    onSetWeekList(weeks)
    onSelectWeek(initialSelectedWeek)
    onSetSectionInfo(getSectionInfo(viewConfig.name, data.cube.editorView.info))
    onSetEventFilters(eventFilters)
    onSetEventList(events)
    onSetViewConfig(viewConfig)
    setReadyToRender(true)
  }

  if (loading) return <Loader active />
  if (error) return <p>{`Error! ${error.message}`}</p>

  if (readyToRender) {
    return (
      <div className="bootstrap-wrapper">
        <div className="container-fluid nopadding">
          {}
          <div
            className="row"
            style={{ backgroundColor: "#EFEDE8", padding: "2em", marginBottom: "1.5em" }}
          >
            {currentSectionInfo && <SectionInfo />}
          </div>
          <div className="row">
            <div className="container-fluid">
              <Tab menu={{ secondary: true }} panes={panes} />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Loader active />
  }
}

const SectionCanvas: React.FC<any> = props => {
  const viewConfig = {
    name: null,
    id: null
  }
  const parts = props.pathname.split("/")
  const paramId = parts.pop() || parts.pop()
  const paramName = parts.pop().toUpperCase()
  const types: any = VIEW_TYPES
  const resourceName = Object.keys(VIEW_TYPES).includes(paramName) ? types[paramName] : null

  const isValid = resourceName ? true : false
  if (isValid) {
    viewConfig.name = resourceName
    viewConfig.id = paramId
  }

  return (
    <FullPageSidebar displayContextSearch={true}>
      <div style={{ overflow: "hidden" }}>
        {viewConfig.name ? (
          <Sections {...props} viewConfig={viewConfig} />
        ) : (
          <p>El recurso no es válido. Inténtalo nuevamente</p>
        )}
      </div>
    </FullPageSidebar>
  )
}

const mapStateToProps = (state: any) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  currentModuleConfig: state.config.moduleConfig,
  currentEventList: state.canvasEditor.currentEventList,
  currentSectionInfo: state.canvasEditor.currentSectionInfo,
  currentViewConfig: state.canvasEditor.currentViewConfig
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionCanvas)
