import moment from "moment"
import { Event, CalendarRestriction } from "../../../../../canvas/redux/types"

export enum DAY_NAMES {
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
}

export default class SchedulerAPI {
  constructor() {}

  // ---------------------------------------------------------------
  // Initial config
  //
  // ---------------------------------------------------------------

  /**
   * Receive list of restictions and single date
   * return if these single date is part of restricted area 
  getRestrictions = (restrictionsList: Array<CalendarRestriction>, date: Date) => {
    const restrictions: Array<CalendarRestriction> = restrictionsList
    const qDate = moment(new Date(date))
    let restriction: string | null = null

    restrictions.map((item: CalendarRestriction) => {
      if (qDate.isBetween(moment(item.start), moment(item.end))) {
        restriction = item.id
      }
    })

    return restriction
  } */

  // ---------------------------------------------------------------
  // interactions
  //
  // ---------------------------------------------------------------

  /**
   * Take an array of events, current movement and return new array
   * with the new position (of dates)  
  moveEvent = (
    eventArray: IEventArray[],
    event: IEvent,
    start: Date,
    end: Date,
    droppedOnAllDaySlot: boolean
  ) => {
    //const events: IEventArray[] = eventArray

    const events = eventArray

    const idx = events.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    console.log(`${event.title} was dropped onto ${updatedEvent.start}`)

    return nextEvents
  } */

  moveEvent = function(eventList: Array<Event>, currentEvent: Event) {
    if (!eventList.length) return []

    const newEventList = eventList
    const sessionId = currentEvent.sessionId
    const startDate = currentEvent.start
    const endDate = currentEvent.end

    newEventList.map((event: Event, index: number) => {
      if (event.sessionId === sessionId) {
        const date = newEventList[index]["start"]
        const daytoset = startDate.getDay()
        const currentDay = date.getDay()
        const distance = daytoset - currentDay
        const tmpDate = new Date(date.setDate(date.getDate() + distance))

        newEventList[index]["start"] = new Date(
          tmpDate.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds())
        )

        // updating block range
        newEventList[index].resource.blockRange.start.startingTime = `${newEventList[index][
          "start"
        ].getHours()}:${
          newEventList[index]["start"].getMinutes().toString().length > 1
            ? newEventList[index]["start"].getMinutes().toString()
            : "0" + newEventList[index]["start"].getMinutes().toString()
        }:00`

        newEventList[index].resource.blockRange.start.day =
          DAY_NAMES[newEventList[index]["start"].getDay() - 1]

        newEventList[index]["end"] = new Date(
          tmpDate.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds())
        )
        // updating block range
        newEventList[index].resource.blockRange.end.endingTime = `${newEventList[index][
          "end"
        ].getHours()}:${
          newEventList[index]["end"].getMinutes().toString().length > 1
            ? newEventList[index]["end"].getMinutes().toString()
            : "0" + newEventList[index]["end"].getMinutes().toString()
        }:00`
      }
    })

    return newEventList
  }

  resizeEvent = function(eventList: Array<Event>, currentEvent: Event) {
    const nextEvents = eventList.map((existingEvent: Event) => {
      return existingEvent.sessionId == currentEvent.sessionId
        ? { ...existingEvent, start: currentEvent.start, end: currentEvent.end }
        : existingEvent
    })

    return nextEvents
  }

  // actions
  addRestriction() {}
  addSection() {}

  /**
   * Receive list of events and filter by criteria */
  filterEventList = (
    originalEventArray: Array<Event>,
    currentEventArray: Array<Event>,
    categoryID: number,
    display: boolean,
    filtercriteria: string
  ) => {
    if (!originalEventArray.length) return []

    let events: Array<object & any> = []
    if (display === false) {
      events = currentEventArray.filter((item: any) => item[filtercriteria] !== categoryID)
    } else {
      events = [
        ...currentEventArray,
        ...originalEventArray.filter((item: any) => item[filtercriteria] === categoryID)
      ]
    }
    return events
  }

  /**
   * Filter restricted areas */
  filterRestrictedAreas = ({ areatype, checked }: { areatype: string; checked: boolean }) => {
    // DANGER: this method (first approach) modifies the DOM directly. Avoid if possible.
    const className = `.restrictedArea.${areatype}`
    const domNodesArray: any = window.document.querySelectorAll(className)

    if (checked) {
      for (var i = domNodesArray.length; i--; ) {
        domNodesArray[i].className = domNodesArray[i].className + " active"
      }
    } else {
      for (var i = domNodesArray.length; i--; ) {
        domNodesArray[i].classList.remove("active")
      }
    }
  }

  // ---------------------------------------------------------------
  // utils
  //
  // ---------------------------------------------------------------

  /**
   * Movement validations */
  // checkIfMovementIsValid = (event: IEvent, start: Date, end: Date, droppedOnAllDaySlot?: any) => {
  //   return true
  // }
}

/*
var droppedOnAllDaySlot: { droppedOnAllDaySlot: boolean; }

Argument of type '{ droppedOnAllDaySlot: boolean; }' is not assignable to parameter of type 'boolean | undefined'.
Type '{ droppedOnAllDaySlot: boolean; }' is not assignable to type 'true'.ts(2345) */
