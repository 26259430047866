export enum ApiTypes {
  GRAPHQL = "graphql",
  APIREST = "apirest"
}
export enum ApiProtocol {
  HTTP = "http",
  https = "https",
  ws = "ws"
}

export type ApiConnection = {
  type: ApiTypes
  protocol: ApiProtocol
  endpoint: String
}

export interface DarwinedConfig {
  moduleCode: String
  moduleName: String
  layoutName: String
  apiConnection: ApiConnection

  toString(): void
}

export class ModuleConfig implements DarwinedConfig {
  moduleCode: string
  moduleName: string
  layoutName: string
  apiConnection: ApiConnection

  constructor(module: string, name: string, layout: string, api: ApiConnection) {
    this.moduleCode = module
    this.moduleName = name
    this.layoutName = layout
    this.apiConnection = api
  }

  toString(): void {
    console.log(`
        code = ${this.moduleCode}
        name = ${this.moduleName}
        layout = ${this.layoutName}
        api = ${JSON.stringify(this.apiConnection)}
    `)
  }
}
