import React, { Component } from "react"
import { Accordion } from "@foris/foris-ui"
import SessionItem from "./SessionItem"

export default class SessionAccordion extends Component<any, {}> {
  state = { activeIndex: 0 }

  handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: any) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { sessions } = this.props

    return (
      <Accordion fluid styled>
        {sessions.map((session: any, index: number) => (
          <SessionItem
            key={index}
            active={activeIndex === index}
            index={index}
            onClick={this.handleClick}
          />
        ))}
      </Accordion>
    )
  }
}
