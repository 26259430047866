import gql from "graphql-tag"

const COURSES_QUERY = gql`
  query megaSearch($scenarioId: ID!, $originId: ID!, $filter: PaginationInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      coursesWithSections(filter: $filter) {
        items {
          id
          name
          code
          curriculum {
            name
            program {
              id
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`

const PACKAGES_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter) {
        items {
          id
          code
          index
          population {
            curriculum
            level
            program {
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

const POPULATIONS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      populations(filter: $filter) {
        items {
          id
          level
          code
          curriculum
          program {
            code
            name
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

const SECTIONS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sections(filter: $filter) {
        items {
          id
          code
          course {
            name
            code
          }
          courseComponent {
            component {
              code
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`
const INSTRUCTORS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructors(filter: $filter) {
        items {
          id
          name
          code
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classrooms(filter: $filter) {
        items {
          id
          code
          capacity
          allowedOverflow
          classroomType {
            code
            name
          }
          building {
            name
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`

export {
  COURSES_QUERY,
  PACKAGES_QUERY,
  POPULATIONS_QUERY,
  SECTIONS_QUERY,
  INSTRUCTORS_QUERY,
  CLASSROOMS_QUERY
}
