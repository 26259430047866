import React, { useState } from "react"
import { connect } from "react-redux"
import ReactTable from "react-table"
import { useApolloClient } from "react-apollo"
import { Form, Grid, Button, Checkbox, Container } from "@foris/foris-ui"
import styled from "styled-components"
import { GET_INSTRUCTOR_QUERY } from "./queries"

const TableWrapper = styled.div`
  & > .ReactTable .rt-table {
    max-height: 200px;
  }
`

const SearchInstructor: React.FC<any> = ({
  currentModuleConfig,
  pathname,
  setVisibleInstructorSearch,
  onSelectedNewInstructor
}) => {
  const parts = pathname.split("/")
  const sectionId = parts.pop() || parts.pop()

  const client = useApolloClient()

  const [isLoading, setIsLoading] = useState(false)
  const [instructors, setInstructors] = useState([])

  // Form values
  const [respectInstructorAvailability, setRespectInstructorAvailability] = useState(false)
  const [respectRequiredInstructor, setRespectRequiredInstructor] = useState(false)

  const columns = [
    {
      Header: "ID",
      width: 50,
      accessor: "id",
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{`${props.value}`}</div>
      }
    },
    {
      Header: "Código",
      width: 120,
      accessor: "code",
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{`${props.value}`}</div>
      }
    },
    {
      Header: "Nombre",
      accessor: "name",
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{`${props.value}`}</div>
      }
    },
    {
      Header: "Prioridad",
      accessor: "priority",
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{`${props.value}`}</div>
      }
    },
    {
      Header: " ",
      accessor: "id",
      Cell: (row: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              size="mini"
              primary
              onClick={(e: any) => onSelectedNewInstructor(row.original, e)}
            >
              usar
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <a className="back__btn" onClick={() => setVisibleInstructorSearch()} title="Volver">
        « volver
      </a>
      <Grid style={{ marginTop: "5px", padding: "0 3em" }}>
        <Grid.Row>
          <Grid.Column width={11}>
            <Container style={{ padding: "0px" }}>
              <h5>Buscar docente para la sesión {sectionId}</h5>
              <hr />
              <Form>
                <Container>
                  <Grid.Row style={{ marginBottom: "1em" }}>
                    <Grid.Column>
                      <div style={{ marginRight: "1em", display: "inline" }}>Respetar:</div>
                      <div style={{ marginRight: "1em", display: "inline" }}>
                        <Checkbox
                          label="Docentes requeridos"
                          onClick={() => {
                            setRespectRequiredInstructor(!respectRequiredInstructor)
                          }}
                        />
                      </div>
                      <div style={{ marginRight: "1em", display: "inline" }}>
                        <Checkbox
                          label="Capacidad"
                          onClick={() => {
                            setRespectInstructorAvailability(!respectInstructorAvailability)
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Container>

                <h4>{instructors.length} resultados </h4>

                <TableWrapper>
                  <ReactTable
                    loading={isLoading}
                    data={instructors}
                    columns={columns}
                    minRows={3}
                    showPagination={false}
                    loadingText={"Cargando..."}
                    noDataText={"Sin resultados para mostrar"}
                  />{" "}
                </TableWrapper>

                <div style={{ padding: "1em 0 0 0" }}>
                  <Form.Button
                    primary
                    onClick={async () => {
                      setIsLoading(true)
                      const { data } = await client.query({
                        query: GET_INSTRUCTOR_QUERY,
                        variables: {
                          originId: currentModuleConfig.originId,
                          scenarioId: currentModuleConfig.scenarioId,
                          filter: {
                            sectionId: sectionId,
                            respectInstructorAvailability,
                            // respectInstructorHours,
                            respectRequiredInstructor
                          }
                        }
                      })
                      if (data && data.cube) setInstructors(data.cube.editorInstructors.items)
                      setIsLoading(false)
                    }}
                  >
                    Buscar
                  </Form.Button>
                </div>
              </Form>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  pathname: state.router.location.pathname,
  currentModuleConfig: state.config.moduleConfig
})

export default connect(mapStateToProps)(SearchInstructor)
