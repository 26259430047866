/*eslint-disable react/react-in-jsx-scope*/
import Landing from "./pages/landing"
import InstructorList from "./pages/instructor-list"
import Instructor from "./pages/instructor"

const InstructorsOfferList = () => <div>InstructorsOfferList</div>
const InstructorsOffer = () => <div>InstructorsOffer</div>

export const base = "/wizard"

export const moduleRoutes = [
  {
    path: `${base}/instructors`,
    component: InstructorList,
    name: "wizard@instructor_list"
  },
  {
    path: `${base}/instructor/:instructor_id`,
    component: Instructor,
    name: "wizard@instructor_view"
  },
  {
    path: `${base}/instructors_for_courses`,
    component: InstructorsOfferList,
    name: "wizard@instructors_offer_list"
  },
  {
    path: `${base}instructors_for_course/:course_id`,
    component: InstructorsOffer,
    name: "wizard@instructors_offer_view"
  },
  {
    path: base,
    component: Landing,
    name: "Landing"
  }
]
