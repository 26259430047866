import React from "react"
import { connect } from "react-redux"
import { useMutation } from "react-apollo"
import { Grid, Segment, Button } from "@foris/foris-ui"
import styled from "styled-components"

import { UPDATE_EVENT_MUTATION } from "../../../../../canvas/queries"

const ErrorContainer = styled.div`
  background-color: rgb(255, 95, 87);
  color: white;
  max-width: 400px;
  padding: 1.5em;
  & > .editorSectionTitle {
    font-size: 1.5em;
  }
  & > p {
    margin-bottom: 30px;
  }
`

const DAY_NUMBER: any = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
}

const ERRORS: any = {
  ClassroomClash: "Choque de sala",
  InstructorClash: "Choque en instructor",
  LinkClash: "Choque en liga",
  SectionClash: "Choque en sección",
  PackageClash: "Choque en paquete",
  InvalidInstructorTravel: "Traslado de docente",
  InvalidPackageTravel: "Traslado de estudiantes",
  InvalidBuildingForCourse: "Edificio no permitido",
  InvalidBlock: "Rango de horarios inválido"
}

const SegmentWrapper = styled.div`
  .mainTitle__container {
    width: 436px;
    display: inline;
  }
  p.mainTitle {
    font-size: 1.1em;
    max-width: 88%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-top: 2px;
    text-overflow: ellipsis;
    float: left;
    margin-right: 5px;
  }
`

const blockRangeToDates = (
  referenceDate: Date,
  startingStringTime: string,
  endingStringTime: string,
  dayName: string
) => {
  // new hours
  const startingHour = startingStringTime.split(":")
  const endingHour = endingStringTime.split(":")

  // set date by dayname
  const date = new Date(referenceDate)
  const daytoset = DAY_NUMBER[dayName]
  const currentDay = date.getDay()
  const distance = daytoset - currentDay
  date.setDate(date.getDate() + distance)

  // create values for start and end dates
  const startingDate = new Date(date)
  const endingDate = new Date(date)

  // set new hours according starting and ending times
  startingDate.setHours(
    parseInt(startingHour[0]),
    parseInt(startingHour[1]),
    parseInt(startingHour[2])
  )
  endingDate.setHours(parseInt(endingHour[0]), parseInt(endingHour[1]), parseInt(endingHour[2]))

  return {
    start: new Date(startingDate),
    end: new Date(endingDate)
  }
}

const ErrorPopup = (props: any) => {
  console.log("| --- props from error popup ----> ", props)
  const { event } = props
  const errors = event.error.details
  const errorList = errors.map((item: any) => item.__typename)
  const uniqueList = errorList.filter((item: any, pos: any) => errorList.indexOf(item) === pos)

  const [updateSession, { loading, error }] = useMutation(UPDATE_EVENT_MUTATION, {
    onCompleted: (response: any) => {
      if (response.cube.editSession.commited) {
        const times = blockRangeToDates(
          props.event.start,
          response.cube.editSession.session.assignment.blockRange.start.startingTime,
          response.cube.editSession.session.assignment.blockRange.end.endingTime,
          response.cube.editSession.session.assignment.day
        )
        props.onEdit({
          start: times.start,
          end: times.end,
          event: { ...props.event },
          sessionId: response.cube.editSession.session.id
        })
        console.log("success", "Sesión actualizada", "La sesión se ha actualizado correctamente")
      } else {
        console.log("ERROR", "Sesión NO actualizada")
      }
    }
  })

  return (
    <SegmentWrapper>
      <Segment style={{ padding: "1.8em" }}>
        <Grid>
          <ErrorContainer>
            <span className="editorSectionTitle">La operación es inválida</span>
            <ul>
              {uniqueList.map((item: any, index: number) => (
                <li key={index}> {ERRORS[item]}</li>
              ))}
            </ul>
            <p>
              Modifique los datos de la sesión y realice una validación nuevamente o ignore esta
              validación.
            </p>
            {uniqueList[0] !== "InvalidBlock" && (
              <Button
                inverted
                loading={loading}
                onClick={(e: any) => {
                  e.preventDefault()
                  const params = {
                    originId: props.currentModuleConfig.originId,
                    scenarioId: props.currentModuleConfig.scenarioId,
                    input: {
                      sessionId: props.event.sessionId,
                      dryRun: false,
                      skipValidations: true,
                      clientMutationId: "mutation",
                      changeset: {
                        changeBlocks: {
                          op: "CHANGE",
                          day: props.event.resource.blockRange.start.day,
                          startTime: props.event.resource.blockRange.start.startingTime,
                          endTime: props.event.resource.blockRange.end.endingTime
                        }
                      }
                    }
                  }
                  updateSession({ variables: { ...params } })
                }}
              >
                Ignorar Validaciones
              </Button>
            )}
            <Button
              inverted
              color="grey"
              onClick={() => {
                const times = blockRangeToDates(
                  props.event.start,
                  props.event.prevResource.blockRange.start.startingTime,
                  props.event.prevResource.blockRange.end.endingTime,
                  props.event.prevResource.blockRange.start.day
                )
                props.onUndo({
                  event: props.event,
                  start: times.start,
                  end: times.end,
                  sessionId: props.event.sessionId
                })
              }}
            >
              Deshacer
            </Button>
          </ErrorContainer>
        </Grid>
      </Segment>
    </SegmentWrapper>
  )
}

const mapStateToProps = (state: any) => ({
  currentModuleConfig: state.config.moduleConfig
})

export default connect(mapStateToProps)(ErrorPopup)
