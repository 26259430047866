import gql from "graphql-tag"

const SECTION_QUERY_SECTION = gql`
  query sectionEditorView(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on SectionInfo {
            campus {
              id
              code
            }
            department {
              code
              name
            }
            modality {
              code
              name
            }
            shift {
              code
              name
            }
            program {
              code
              name
            }
            curriculum {
              code
              name
            }
            course {
              code
              name
              level
            }
            courseComponent {
              component {
                code
              }
              stripePriorities {
                stripe {
                  code
                }
              }
              classroomPriorities {
                classroomType {
                  code
                }
              }
              academicStandard
              modulation
            }
            section {
              id
              code
              vacancies
            }
            packages {
              id
              index
              code
            }
          }
        }
        sections {
          id
          sessions {
            id
            section {
              code
              vacancies
              course {
                code
                name
              }
              courseComponent {
                component {
                  code
                }
              }
            }
            scheduleEvent {
              id
              start
              end
            }
            events {
              block {
                id
              }
            }
            assignment {
              blockRange {
                start {
                  day
                  startingTime
                }
                end {
                  endingTime
                }
              }
              classroom {
                id
                name
                code
                classroomType {
                  code
                  name
                }
                building {
                  name
                }
                capacity
                allowedOverflow
              }
              instructor {
                id
                name
                code
              }
            }
          }
        }
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
      }
    }
  }
`

const SECTION_QUERY_INSTRUCTOR = gql`
  query instructorEditorView(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on InstructorInfo {
            instructor {
              id
              code
              name
              contract
              priority
              minBlocks
              maxBlocks
              maxSections
              maxCourseComponents
              minCourses
            }
            assignedBlocks
            assignedSections
            assignedCourses
          }
        }
        sections {
          id
          sessions {
            id
            section {
              code
              vacancies
              course {
                code
                name
              }
              courseComponent {
                component {
                  code
                }
              }
            }
            scheduleEvent {
              id
              start
              end
            }
            assignment {
              blockRange {
                start {
                  day
                  startingTime
                }
                end {
                  endingTime
                }
              }
              classroom {
                id
                name
                code
                classroomType {
                  code
                  name
                }
                building {
                  name
                }
                capacity
                allowedOverflow
              }
              instructor {
                id
                name
                code
              }
            }
          }
        }
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
      }
      editorRestrictions(resourceType: $resourceType, resourceId: $resourceId) {
        type
        scheduleEvent {
          id
          dayId
          start
          end
        }
      }
    }
  }
`

const UPDATE_EVENT_MUTATION = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $input: EditSessionInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editSession(input: $input) {
        commited
        skippedValidations
        validationErrors {
          ... on InvalidBlock {
            __typename
            isValidStartTime
            isValidEndTime
          }

          ... on ClassroomClash {
            __typename
            interval {
              name
              startingDate
              endingDate
            }
            block {
              startingTime
              endingTime
            }
            sessions {
              id
            }
            classroom {
              id
            }
          }
          ... on InstructorClash {
            __typename
            block {
              startingTime
              endingTime
            }
            interval {
              name
              startingDate
              endingDate
            }
            sessions {
              id
            }
            instructor {
              id
              code
            }
            block {
              startingTime
              endingTime
            }
          }
          ... on LinkClash {
            __typename
            block {
              startingTime
              endingTime
            }
            interval {
              name
              startingDate
              endingDate
            }
            sessions {
              id
            }
            link {
              id
              vacancies
            }
          }
          ... on PackageClash {
            __typename
            block {
              startingTime
              endingTime
            }
            interval {
              startingDate
              endingDate
            }
            sessions {
              id
            }
            package {
              id
              code
              index
            }
          }
          ... on InvalidInstructorTravel {
            __typename
            instructor {
              id
              name
            }
            withPrevious {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
            withFollowing {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
          }
          ... on InvalidPackageTravel {
            __typename
            package {
              id
              code
            }
            withPrevious {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
            withFollowing {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
          }
        }
        session {
          id
          vacancies
          assignment {
            day
            blockRange {
              start {
                startingTime
              }
              end {
                endingTime
              }
            }
          }
          scheduleEvent {
            id
            start
            end
          }
        }
      }
    }
  }
`

export { SECTION_QUERY_SECTION, SECTION_QUERY_INSTRUCTOR, UPDATE_EVENT_MUTATION }
