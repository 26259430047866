import {
  SearchActionTypes,
  ContextSearchState,
  SET_FILTER,
  SET_SEARCH_CRITERIA,
  RECEIVE_RESULT_LIST
} from "./types"

const initialState: ContextSearchState = {
  currentFilter: null,
  currentSearchCriteria: null,
  currentResultList: null
}

export default function(state = initialState, action: SearchActionTypes): ContextSearchState {
  switch (action.type) {
    case SET_FILTER: {
      return {
        ...state,
        currentFilter: action.payload
      }
    }
    case SET_SEARCH_CRITERIA: {
      return {
        ...state,
        currentSearchCriteria: action.payload
      }
    }
    case RECEIVE_RESULT_LIST: {
      return {
        ...state,
        currentResultList: action.payload[Object.keys(action.payload)[0]] || []
      }
    }
    default:
      return state
  }
}
