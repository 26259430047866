import React, { Component } from "react"
import styled from "styled-components"

const SingleBox = styled.div`
  color: white;
  & > span {
    display: block;
  }
`
const InfoSingleBox: React.FC<{ title: string; content: string }> = ({ ...props }) => {
  const { title, content } = props
  return (
    <SingleBox>
      <span>{title}</span>
      <span>{content}</span>
    </SingleBox>
  )
}
export { InfoSingleBox }
