import React, { Component } from "react"
import { Grid, Image } from "@foris/foris-ui"
import { InfoSingleBox } from "./InfoSingleBox"

export default class SectionInfo extends Component {
  render() {
    const info = this.props
    return (
      <Grid columns="ten" style={{ maxWidth: "1200px" }}>
        <h1 style={{ color: "white", fontSize: "1.6em" }}>
          {" "}
          9998001890 PRACTICA Estancia complementaria optativa I: ECOE (Evaluación Clínica Objetiva
          Estructurada)
        </h1>
        <Grid.Row>
          <Grid.Column>
            <InfoSingleBox title={"sede"} content={"MAD"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"escuela"} content={"28051852"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"modalidad"} content={"TR"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"jornada"} content={"U"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"carrera"} content={"9998"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"curriculo"} content={"001"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"nivel"} content={"600"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"grupo"} content={"MG6"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"seccion"} content={"303319"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"paquetes"} content={"U:TR:9998:001:600:9"} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <InfoSingleBox title={"modulacion"} content={"1:3"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"estandar"} content={"40"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"franjas"} content={"MAN, MAN1, MAN2, MAN3, MAN4, MAN5"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"tipoSala"} content={"HOSP-SIM-COMP"} />
          </Grid.Column>
          <Grid.Column>
            <InfoSingleBox title={"vacantes"} content={"16"} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
