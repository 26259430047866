import {
  SchedulerActionsTypes,
  SchedulerState,
  Week,
  EventList,
  EVENT_STATES,
  SET_CURRENT_EVENT_LIST,
  SET_ERROR_STATUS,
  SET_CURRENT_SECTION_INFO,
  SET_NEW_EVENTS_POSITION,
  SET_CURRENT_WEEK_LIST,
  SET_CURRENT_SELECTED_WEEK,
  SET_CURRENT_SELECTED_WEEK_BY_POSITION,
  SET_CURRENT_VIEW_CONFIG,
  SET_CURRENT_EVENT_FILTERS,
  SET_CURRENT_CALENDAR_RESTRICTIONS
} from "./types"

const initialState: SchedulerState = {
  currentWeekList: null,
  currentEventList: null,
  currentSectionInfo: null,
  currentSelectedWeek: null,
  currentViewConfig: null,
  currentEventFilters: null,
  currentCalendarRestrictions: []
}

export default function(state = initialState, action: SchedulerActionsTypes): SchedulerState {
  switch (action.type) {
    case SET_CURRENT_EVENT_LIST: {
      return {
        ...state,
        currentEventList: action.payload
      }
    }

    /**
     * Update position previous to
     * request to serverside.
     * > change state here: Unmodified --> Modified */
    case SET_NEW_EVENTS_POSITION: {
      const tmpList: EventList = state.currentEventList || []
      const sessionId = action.payload.event.sessionId
      tmpList.map((item: any, index: any) => {
        if (item.sessionId === sessionId) {
          tmpList[index]["state"] = EVENT_STATES.MODIFIED
        }
      })
      return { ...state, currentEventList: tmpList }
    }

    case SET_ERROR_STATUS: {
      console.log("PAYLOAD ON SET_ERROR_STATUS --->", action.payload)

      const tmpList: EventList = state.currentEventList || []
      const commited = action.payload.commited
      const sessionId = action.payload.sessionId
      tmpList.map((item, index) => {
        if (item.sessionId === sessionId) {
          tmpList[index]["error"] =
            commited === false
              ? { status: true, details: action.payload.validationErrors }
              : { status: false, details: [] }
          tmpList[index]["state"] = EVENT_STATES.UPDATED
        }
      })

      return { ...state, currentEventList: tmpList }
    }

    case SET_CURRENT_SECTION_INFO: {
      return { ...state, currentSectionInfo: action.payload }
    }

    case SET_CURRENT_WEEK_LIST: {
      return {
        ...state,
        currentWeekList: action.payload
      }
    }

    case SET_CURRENT_SELECTED_WEEK: {
      return {
        ...state,
        currentSelectedWeek: action.payload
      }
    }

    case SET_CURRENT_SELECTED_WEEK_BY_POSITION: {
      let newSelectedWeek = state.currentSelectedWeek
      const list = state.currentWeekList ? state.currentWeekList : []
      const elementToFind: Week = state.currentSelectedWeek
        ? state.currentSelectedWeek
        : ({} as Week)
      const currentIndex = list.indexOf(elementToFind)

      if (action.payload === "PREV" && list.length) {
        newSelectedWeek = currentIndex > 0 ? list[currentIndex - 1] : list[list.length - 1]
      }

      if (action.payload === "NEXT" && list.length) {
        newSelectedWeek = currentIndex < list.length - 1 ? list[currentIndex + 1] : list[0]
      }

      return {
        ...state,
        currentSelectedWeek: newSelectedWeek
      }
    }

    case SET_CURRENT_VIEW_CONFIG: {
      return {
        ...state,
        currentViewConfig: action.payload
      }
    }

    case SET_CURRENT_EVENT_FILTERS: {
      return {
        ...state,
        currentEventFilters: action.payload
      }
    }

    case SET_CURRENT_CALENDAR_RESTRICTIONS: {
      return {
        ...state,
        currentCalendarRestrictions: action.payload
      }
    }

    default:
      return state
  }
}
