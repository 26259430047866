import React, { Component, Props, createRef } from "react"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import { Menu, Ref, Sidebar, Icon } from "@foris/foris-ui"
import MainMenu from "./components/MainMenu"
import ContextSearchInstance from "./components/ContextSearch"

import logo from "../../../../assets/svg/Darwin_Editor.svg"
import "@foris/foris-ui/lib/styles.css"
interface FullpageSidebarProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
  displayContextSearch: boolean
}

class FullpageSidebar extends Component<FullpageSidebarProps, { visible: boolean }> {
  state = { visible: false }
  segmentRef = createRef()

  handleToggleClick = () => this.setState({ visible: !this.state.visible })
  handleSidebarHide = () => this.setState({ visible: false })

  render() {
    const { visible } = this.state
    const { displayContextSearch } = this.props
    return (
      <div style={{ minHeight: "100vh", display: "flex", flexFlow: "column nowrap" }}>
        <Menu size="mini" className="ui top attached menu">
          <a style={{ height: "50px" }} className="item" onClick={this.handleToggleClick}>
            <i className="sidebar icon" />
          </a>

          <Link to={"/scheduler/editor"} style={{ display: "flex" }}>
            <img
              src={logo}
              alt="Editor Darwined"
              width="225"
              height="auto"
              style={{ paddingLeft: "1em" }}
            />
          </Link>

          {displayContextSearch && (
            <div style={{ width: "100%" }}>
              <div style={{ margin: "7px auto 0 auto", maxWidth: "466px" }}>
                <ContextSearchInstance />
              </div>
            </div>
          )}

          <Menu.Menu position="right">
            {/* <Menu.Menu position="right">
              <Dropdown item text="Ambiente">
                <Dropdown.Menu>
                  <Dropdown.Item>Base</Dropdown.Item>
                  <Dropdown.Item>Base2</Dropdown.Item>
                  <Dropdown.Item>Base3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu position="right">
              <Dropdown item text="Escenario">
                <Dropdown.Menu>
                  <Dropdown.Item>Madrid</Dropdown.Item>
                  <Dropdown.Item>Canarias</Dropdown.Item>
                  <Dropdown.Item>Valencia</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu> */}

            <Menu.Item style={{ cursor: "pointer" }}>
              <MainMenu />
            </Menu.Item>
            <Menu.Item name="user_settings">
              <Icon name="user" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Sidebar.Pushable style={{ flex: 1 }}>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            onHide={this.handleSidebarHide}
            vertical
            target={this.segmentRef}
            visible={visible}
            width="thin"
            style={{ zIndex: 99999 }}
          >
            <Menu.Item>
              <Link to="/scheduler/editor"> Editor</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/">Menu 2</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/">Menu 3</Link>
            </Menu.Item>
          </Sidebar>

          <Ref innerRef={this.segmentRef}>
            <div>{this.props.children}</div>
          </Ref>
        </Sidebar.Pushable>
      </div>
    )
  }
}

export default withRouter(FullpageSidebar)
