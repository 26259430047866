import React, { Component, Fragment } from "react"
import Header from "./Header"
//import "../../../assets/scss/app.scss"

export default class DefaultLayout extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="module__default_layout">{this.props.children}</div>
      </Fragment>
    )
  }
}
